<template>
    <section v-show="isTitleBarVisible" class="section">
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <ul>
                        <li class="current">{{ titleBarTitleCurrent }}</li>
                        <li v-for="(item, index) in titleBarTitleStack" :key="index">
                            <p @click="redirect(item)" style="cursor:pointer">{{ item.title }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: "TitleBar",

    computed: mapState([
        'isTitleBarVisible',
        'titleBarTitleStack',
        'titleBarTitleCurrent'
    ]),

    methods: {
        redirect (item) {
            this.$router.push(item)
        }
    },
}
</script>