<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            name: {
                type: String,
                default: null,
            },
            prefill: {
                type: [String, Number, Boolean, Array],
                default: null,
            },
            size: {
                type: Number,
                default: 3,
            },
            displayAsTable: {
                type: Boolean,
                default: false,
            },
            addColumnClass: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                internValue: '',
                classes: [],
            }
        },

        watch: {
            prefill(newValue) {
                this.value = newValue
            }
        },

        computed: {
            value: {
                set(newValue) {
                    this.internValue = newValue
                },

                get() {
                    return this.internValue
                },
            },

            settings: {
                get() {
                    return {
                        name: this.name,
                        disabled: (typeof this.name != 'string' || this.name.length <= 1)
                    }
                },
            },
        },

        mounted() {
            this.value = this.prefill || ''
            this.classes.push('is-' + this.size)
            if (this.addColumnClass) {
                this.classes.push('column')
            }
            if (this.displayAsTable) {
                this.classes.push('inner-columns')
            }
        },

        methods: {
            updateValue(value) {
                this.value = value
            }
        },
    }
</script>

<template>
    <b-field :label="label" :class="classes">
        <slot :get="settings"></slot>
    </b-field>
</template>
