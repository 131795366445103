const each = require('lodash/each')
const isEqual = require('lodash/isEqual')

class JbUtils {
    constructor($html, store) {
        this.$html = $html
        this.store = store
        this.asideInactiveKey = 'is-aside-wide-inactive'
        this.asideWideStorageKey = 'is-aside-wide-active'
        this.htmlClass = {
            aside: 'is-aside-active',
            asideSmall: 'is-aside-small-active',
            asideMobile: 'is-aside-mobile-active',
            overlay: 'has-ui-overlay',
            navbarMenu: 'is-active',
            navbarMenuToggle: 'is-active',
        }
        this.overlayIteration = -1
        this.addedClasses = []
        this.navbarMenuId = 'navbar-menu'
        this.overlayHideCallback = null

        // Initial state
        this.toggle(window.localStorage.getItem(this.asideWideStorageKey));

        // Overlay ESC-key close
        document.addEventListener('keydown', e => {
            if (e.which === 27)
                this.overlayHide();
        });

        // Overlay click close
        const uiOverlay = document.getElementById('ui-overlay')

        if (uiOverlay) {
            uiOverlay.addEventListener('click', () => {
                this.overlayHide();
            });
        }

        // Card Widget Tiles per row depends on viewport width
        this.commitCardWidgetTilesPerRow()

        window.addEventListener('resize', () => {
            this.commitCardWidgetTilesPerRow()
        })
    }

    static calculateCardWidgetTilesPerRow() {
        return window.innerWidth < 1400?3:5
    }

    commitCardWidgetTilesPerRow() {
        this.store.commit('basic', {
            key: 'cardWidgetTilesPerRow',
            value: JbUtils.calculateCardWidgetTilesPerRow()
        })
    }

    setEvents(elements, mode) {
        each(elements, (el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleWrapper(mode, e);
            })
        });
    }

    toggleWrapper(mode, e) {
        if (typeof mode === 'function') {
            mode(e)
        } else {
            if (mode === 'basic') {
                this.toggle(null, true)
            } else if (mode === 'mobile') {
                this.toggleMobile()
            } else if (mode === 'updates') {
                this.updatesShow()
            }
        }
    }

    toggle(isWide, isDoStorageUpdate = false) {
        if (isWide === null) {
            isWide = this.$html.classList.contains(this.htmlClass.asideSmall)
        }

        if (isWide) {
            this.$html
                .classList
                .remove(this.htmlClass.asideSmall);

            this.$html
                .classList
                .add(this.htmlClass.aside);

            if (this.store) {
                this.store.commit('isAsideWideActive', true)
            }

            if (isDoStorageUpdate)
                window.localStorage.setItem(this.asideWideStorageKey, "1");


        } else {
            this.$html
                .classList
                .remove(this.htmlClass.aside);

            this.$html
                .classList
                .add(this.htmlClass.asideSmall);

            if (this.store) {
                this.store.commit('isAsideWideActive', false)
            }

            if (isDoStorageUpdate)
                window.localStorage.removeItem(this.asideWideStorageKey);
        }
    }

    toggleMobile() {
        if (this.$html.classList.contains(this.htmlClass.asideMobile)) {
            this.overlayHide()
        } else {
            this.overlayShow(this.htmlClass.asideMobile)
        }
    }

    updatesShow() {
        this.overlayShow('has-updates-widget-active')
    }

    overlayHide() {
        if (this.addedClasses[this.overlayIteration]) {
            each(this.addedClasses[this.overlayIteration], addedClass => {
                this.$html.classList.remove(addedClass)
            });
            if (this.overlayIteration > -1) {
                this.overlayIteration--
            }

            if (this.overlayIteration < 0) {
                this.$html.classList.remove(this.htmlClass.overlay)
            }
        }

        if (this.overlayHideCallback) {
            this.overlayHideCallback()
            this.overlayHideCallback = null
        }

        if (this.store) {
            this.store.commit('primaryMenuIsActiveForcedState', null)
            this.store.commit('secondaryMenu', null)
        }
    }

    overlayShow(addedClasses = []) {
        if (typeof addedClasses === 'string')
            addedClasses = [addedClasses];

        if (!isEqual(addedClasses, this.addedClasses[this.overlayIteration])) {
            this.overlayIteration++
            this.addedClasses[this.overlayIteration] = []

            each(addedClasses, addedClass => {
                this.$html.classList.add(addedClass);
                this.addedClasses[this.overlayIteration].push(addedClass);
            });
        }

        if (!this.$html.classList.contains(this.htmlClass.overlay))
            this.$html.classList.add(this.htmlClass.overlay)
    }

    htmlAddClass(c) {
        this.$html.classList.add(c)
    }

    htmlRemoveClass(c) {
        this.$html.classList.remove(c)
    }
}

export default JbUtils