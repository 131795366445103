<script>
  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {}
        },
      },
      span: {
        type: [Number, String],
        default: 1,
      },
      view: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      isParentEditAllowed: {
        type: Boolean,
        default: true,
      },
    },

    methods: {
      clickRowView(row) {
        this.$router.push(this.$root.stringPlaceholders(this.view, row))
      },
    },
  }
</script>

<template>
    <td :colspan="(span + 2)" class="has-background-grey-light">
        <div class="level is-paddingless" style="align-items: center">
            <div class="level-left" style="display: block">
                <strong class="has-text-white">{{ label }}</strong>
            </div>
            <div class="level-right" v-if="isParentEditAllowed">
                <div class="buttons">
                    <button v-if="view.length > 0" @click="clickRowView(data.row)" class="button is-success"
                            type="button" style="border-radius: 50%; border: 1px solid white">
                        <b-icon icon="pencil" custom-size="default"/>
                    </button>
                </div>
            </div>
        </div>
    </td>
</template>
