<script>
    import Card from '@/components/Card'

    export default {
        components: {
            Card,
        },

        mounted() {
            this.$root.restrictCountries('*')
            this.$store.commit('mainPage', {
                current: this.$t('Tags'),
                stack: [{
                    'path': '/tags',
                    'title': this.$t('Tags'),
                }]
            })
        }
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <card class="is-card-table has-pagination has-bottom-left-hidden">
                <bg-table
                        :label="$t('Tags')"
                        :remove="{ id: 'Id', render: row => row.Name, desc: $t('Tag') }"
                        path="tag"
                        :no-changes="true"
                        object="Tag" v-slot="data">
                    <bg-column :label="$t('Name')" sort="Name">{{ data.row.Name }}</bg-column>
                </bg-table>
            </card>
        </div>
    </section>
</template>
