<script>
    export default {
        props: {
            fetched: {},
            create: {
                type: Boolean,
                default: false
            },
        },

        methods: {
            beforeSend(content, call) {
                content['RequiredCount'] = parseInt(content['RequiredCount'])
                return [content, this.$root.stringPlaceholders(call, content)]
            },

            getPropertyValue(type, withUnit, isBool = false, onlyUnit = false) {
                let component2property = this.$root.findProperty(this.fetched.Component2Properties, type)
                let value = '-'
                if (typeof component2property !== 'undefined') {
                    let propertyValue = component2property.Property.PropertyValues[0]
                    if (propertyValue.VValue !== null) {
                        value = propertyValue.VValue
                        if (typeof withUnit !== 'undefined' && withUnit && typeof propertyValue.Unit !== 'undefined') {
                            if (onlyUnit) {
                                value = this.$root.translation(propertyValue.Unit.UnitI18ns).Name
                            } else {
                                value += ' ' + this.$root.translation(propertyValue.Unit.UnitI18ns).Name
                            }
                        }
                        if (isBool) {
                            value = value === 1 ? this.$t('Yes') : this.$t('No')
                        }

                        let secondValue = this.getPropertyValue(type + '2', withUnit, isBool, onlyUnit)
                        if (secondValue && secondValue !== '-') {
                            value += ' | ' + secondValue
                        }
                    } else {
                        value = propertyValue.VText
                    }
                }

                return value
            },
        },
    }
</script>

<template>
    <div>
        <div v-if="create"><p>🦉 {{ $t('This can not be created.') }}</p></div>
        <bg-form v-if="!create" :beforeSend="'beforeSend'" call="folder/${__ID}">

            <bg-form-input :label="$t('Item number')" :prefill="fetched.SapId" :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('Name')" :prefill="$root.translation(fetched.ComponentI18ns).Name" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Name for web')"
                           :prefill="$root.translation(fetched.ComponentI18ns).WebName || $root.translation(fetched.ComponentI18ns).Name"
                           :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('Description (short)')"
                           :prefill="$root.translation(fetched.ComponentI18ns).DescriptionShort" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Description')" :prefill="$root.translation(fetched.ComponentI18ns).Description"
                           :size="12" :textarea="true" :displayAsTable="true"/>
            <div class="is-12 column">
                <hr>
                <h2 class="title" style="font-size: 1.25rem;">{{ $t('Properties') }}</h2>
            </div>
            <bg-form-input :label="$t('Edge')" :prefill="$root.translation(fetched.Edge.EdgeI18ns).Name" :size="12"
                           :displayAsTable="true" v-if="fetched.Edge"/>
            <bg-form-input :label="$t('Weight')" :prefill="getPropertyValue('weight', true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Length')" :prefill="getPropertyValue('length', true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Height')" :prefill="getPropertyValue('height', true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Width')" :prefill="getPropertyValue('width', true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Quantity per package')" :prefill="getPropertyValue('quantity_per_package')"
                           :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('Package unit')" :prefill="getPropertyValue('package_unit', true, false, true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Quantity unit')" :prefill="getPropertyValue('quantity_unit', true, false, true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Feed cross section')" :prefill="getPropertyValue('feed_cross_section', true)"
                           :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('Laying radius')" :prefill="getPropertyValue('laying_radius', true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Ecological balance')"
                           :prefill="getPropertyValue('ecological_balance', false, true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('CO2-balance')" :prefill="getPropertyValue('co2_balance', true)" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Color')" :prefill="getPropertyValue('color')" :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('Gradient')" :prefill="getPropertyValue('gradient')" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Number')" :prefill="getPropertyValue('number')" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Load class')" :prefill="getPropertyValue('load_class')" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Designation')" :prefill="getPropertyValue('designation')" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Closure')" :prefill="getPropertyValue('closure')" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Material')" :prefill="getPropertyValue('material')" :size="12"
                           :displayAsTable="true"/>
            <bg-form-input :label="$t('Mesh')" :prefill="getPropertyValue('mesh')" :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('Number in sale')" :prefill="getPropertyValue('num_in_sale')" :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('DoP Number')" :prefill="getPropertyValue('u_dop')" :size="12" :displayAsTable="true"/>

        </bg-form>
    </div>
</template>
