<script>
    import vue2Dropzone from 'vue2-dropzone'
    import BgUploadModal from '@/components/BgUploadModal'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        props: {
            folderId: {
                type: [String, Number],
                default: '0',
            },
            collection: {
                type: String,
                default: '0',
            },
            collectionId: {
                type: String,
                default: '0',
            },
            reload: {
                type: Boolean,
                default: false
            },
        },

        data () {
            return {
                uploadedFiles: [],
                uploadModal: false,
                disableDeletion: false,
                innerFolderId: this.FolderId,
                dropzoneOptions: {
                    thumbnailWidth: 150,
                    addRemoveLinks: true,
                    url: this.getDropzoneUrl(),
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.Token.get()
                    },
                }
            }
        },

        components: {
            vueDropzone: vue2Dropzone,
            BgUploadModal: BgUploadModal,
        },

        watch: {
            folderId (newFolderId) {
                this.innerFolderId = newFolderId
                this.reloadDropzoneUrl()
            }
        },

        methods: {
            uploadFinished (file, response) {
                this.uploadModalSelected = 0

                let fileObject = {
                    completed: false,
                    response: response.data.File[0]
                }

                if ('dataURL' in file) {
                    fileObject.image = file.dataURL
                }

                this.uploadedFiles.push(fileObject)
            },

            uploadRemoveFile (file) {
                if (!this.disableDeletion) {
                    let uploadedFileId = null

                    try {
                        uploadedFileId = JSON.parse(file.xhr.responseText).data.File[0].Id
                    } catch {
                        if (uploadedFileId === null) {
                            return true
                        }
                    }

                    this.$root.delete(`file/${uploadedFileId}`)
                }
            },

            reloadDropzoneUrl () {
                this.$refs.myVueDropzone.setOption('url', this.getDropzoneUrl())
            },

            getDropzoneUrl () {
                if (typeof this.innerFolderId === 'undefined') {
                    // eslint-disable-next-line
                    this.innerFolderId = this.folderId
                }
                return this.$root.apiBaseUrl + `file?FolderId=${this.innerFolderId}&Collection=${this.collection}&CollectionId=${this.collectionId}`
            },

            done () {
                this.uploadModal = false
                this.disableDeletion = true
                if (this.reload) {
                    window.location.href = window.location.href + '#' + this.folderId
                    window.location.reload()
                } else {
                    this.$route.params.source.pop()
                    this.$router.push({
                        name: 're-details',
                        params: {
                            for: this.$route.params.for,
                            id: this.$route.params.id,
                            folder: this.$route.params.folder,
                            source: this.$route.params.source,
                        }
                    })
                }
            }
        },
    }
</script>

<template>
    <div>
        <div class="card-toolbar" style="padding-bottom: 0">
            <vue-dropzone
                ref="myVueDropzone"
                id="bg-dropzone"
                :options="dropzoneOptions"
                :duplicateCheck="true"
                :useCustomSlot="true"
                @vdropzone-success="uploadFinished"
                @vdropzone-removed-file="uploadRemoveFile"
                >
                <div style="grid-column: span 2">
                    <p style="margin-bottom: .5rem">{{ $t('Drop the file or click on the button') }}</p>
                    <p><button class="button is-primary"><span class="icon is-small"><i class="mdi mdi-plus"></i></span>&nbsp; {{ $t('Add file') }}</button></p>
                </div>
            </vue-dropzone>
        </div>

        <div v-if="uploadedFiles.length > 0" class="level" style="margin: 1rem 0 0">
            <div class="level-left">
            </div>
            <div class="level-right">
                <button class="button is-primary" @click.prevent="uploadModal = true">{{ $t('Finish upload') }}</button>
            </div>
        </div>

        <bg-upload-modal @done="done" @close="done" :show="uploadModal" :folders="$parent.folders" :files="uploadedFiles" :is-upload="true"/>

    </div>

</template>
