<script>
    import moment from 'moment'

    export default {
        props: {
            collection: {
                type: Object,
                required: true,
            },
            label: {
                type: String,
                default: 'auto',
            },
            sort: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                display: {
                    editedDebug: {
                        state: false,
                        permission: ['admin'],
                        label: this.$t('Debug (BgColumnEdited)'),
                    },
                    editedShowEmail: {
                        state: false,
                        label: this.$t('Show email instead of name'),
                    },
                },
            }
        },

        filters: {
            date: function (value) {
                return moment(value).format('DD.MM.YYYY HH:mm:ss')
            },
        },
    }
</script>

<template>
    <bg-column-wrapper :label="label" :sort="sort">
        <template v-if="'UpdatedByUser' in collection">
            {{ collection.UpdatedOn | date }}<br>
            <span v-if="!display.editedShowEmail.state">{{ collection.UpdatedByUser.FirstName }} {{ collection.UpdatedByUser.LastName }}</span><span
                v-if="display.editedShowEmail.state">{{ collection.UpdatedByUser.Email }}</span>
            <div v-if="display.editedDebug.state">
                <ul>
                    <li v-for="(value, key) in collection.UpdatedByUser" :key="key">
                        <strong>{{ key }}:</strong> {{ value }}
                    </li>
                </ul>
            </div>
        </template>
        <template v-if="!('CreatedByUser' in collection)">{{ $t('No information found') }}</template>
    </bg-column-wrapper>
</template>
