<template>
    <table class="bg-status-tool__table bg-status-tool__table--properties">
        <thead>
        <tr>
            <td>{{ $t('Properties') }}</td>
        </tr>
        </thead>
        <tbody>
        <tr class="bg-status-tool-spacer">
            <td></td>
        </tr>
        <tr class="bg-status-tool-page">
            <td>&nbsp;</td>
        </tr>
        <template v-for="(propertyKey, index) in element.propertyKeys">
            <tr :class="{'bg-status-tool-property-value': true, 'hovered': hoveredRow === (propertyKey + '-' + element.identifier), 'clicked': clickedRows.includes(propertyKey + '-' + element.identifier)}"
                v-if="!hideProperty(propertyKey + '-' + element.identifier)"
                :key="index + '-' + element.identifier"
                @click="changeClickedRows(propertyKey + '-' + element.identifier)"
                @mouseenter="handleRowHover(propertyKey + '-' + element.identifier)"
                @mouseleave="handleRowHover('')">
                <td>
                <span>
                    <strong>{{ getPropertyLabel(propertyKey, element.originNodetype) }}</strong>
                </span>
                </td>
            </tr>
        </template>

        <template v-if="Object.keys(element.children).length">
            <template v-for="(child, childIndex) in element.children">
                <tr class="bg-status-tool-spacer" :key="'spacer-' + childIndex">
                    <td></td>
                </tr>
                <tr class="bg-status-tool-page" :key="'page-' + childIndex">
                    <td>&nbsp;</td>
                </tr>
                <template v-for="(propertyKey, index) in child.propertyKeys">
                    <tr :class="{'bg-status-tool-property-value': true, 'hovered': hoveredRow === (propertyKey + '-' + child.identifier), 'clicked': clickedRows.includes(propertyKey + '-' + child.identifier)}"
                        v-if="!hideProperty(propertyKey + '-' + child.identifier)"
                        :key="index + '-' + child.identifier"
                        @click="changeClickedRows(propertyKey + '-' + child.identifier)"
                        @mouseenter="handleRowHover(propertyKey + '-' + child.identifier)"
                        @mouseleave="handleRowHover('')">
                        <td>
                        <span>
                            <strong>{{ getPropertyLabel(propertyKey, child.originNodetype) }}</strong>
                        </span>
                        </td>
                    </tr>
                </template>
            </template>
        </template>

        </tbody>
    </table>
</template>

<script>
    export default {
        name: "StatusToolPropertyKeyTable",
        props: {
            hiddenIdentifiers: {
                type: Array,
            },
            hoveredRow: {
                type: String,
                default: '',
            },
            propertyLabels: {
                type: Object,
            },
            clickedRows: {
                type: Array,
            },
            element: {
                type: Object,
            },
        },
        methods: {
            hideProperty(propertyKey) {
                return this.hiddenIdentifiers.length && this.hiddenIdentifiers.includes(propertyKey);
            },
            handleRowHover(identifier) {
                this.$emit('row-hover', identifier)
            },
            changeClickedRows(identifier) {
                this.$emit('click-row', identifier)
            },
            getPropertyLabel(key, nodeType) {
                if (typeof this.propertyLabels[nodeType] === 'undefined' || typeof this.propertyLabels[nodeType][key] === 'undefined') {
                    return key
                }

                return this.propertyLabels[nodeType][key]
            },
        },
    }
</script>
