import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        /* Used with style switcher */
        fullPageButtonStyleClass: 'is-black',
        stylingThemes: [
            {
                pathname: '/',
                label: 'Default'
            },
        ],

        /* Current user */
        userName: null,
        userRole: null,
        userEmail: null,

        /* Dashboard */
        cardWidgetTilesPerRow: 5,

        /* Aside menu */
        isAsideVisible: true,
        isAsideWideActive: true,
        secondaryMenu: null,
        primaryMenuIsActiveForcedState: null,

        /* Navbar */
        isNavBarVisible: true,
        isNavBarMenusHoverable: false,
        navBarDropdownsForcedClose: null,
        navBarUpdatesLevel: null,

        /* TitleBar */
        isTitleBarVisible: true,
        titleBarTitleStack: null,
        titleBarTitleCurrent: null,

        /* HeroBar */
        isHeroBarVisible: true,
        isHeroBarMainActive: null,
        heroBarHeadline: null,

        /* FooterBar */
        isFooterBarVisible: true,
    },
    mutations: {
        /* A fit-them-all commit */
        basic(state, payload) {
            state[payload.key] = payload.value;
        },

        /* Some specific commit fns */
        isAsideWideActive(state, payload) {
            state.isAsideWideActive = payload
        },
        secondaryMenu(state, payload) {
            state.secondaryMenu = payload
        },
        primaryMenuIsActiveForcedState(state, payload) {
            state.primaryMenuIsActiveForcedState = payload
        },

        mainPage(state, payload) {
            state.isHeroBarMainActive = true
            state.heroBarHeadline = null
            state.titleBarTitleStack = payload.stack
            state.titleBarTitleCurrent = payload.current
        },

        subPage(state, payload) {
            state.isHeroBarMainActive = false
            state.titleBarTitleStack = payload.stack
            state.titleBarTitleCurrent = payload.current
            state.heroBarHeadline = payload.title
        },

        fullPage(state, payload) {
            state.isAsideVisible = !payload
            state.isNavBarVisible = !payload
            state.isTitleBarVisible = !payload
            state.isHeroBarVisible = !payload
            state.isFooterBarVisible = true
        },

        user(state, payload) {
            if (payload.name || payload.name === null)
                state.userName = payload.name

            if (payload.role || payload.role === null)
                state.userRole = payload.role

            if (payload.email || payload.email === null)
                state.userEmail = payload.email
        }
    }
})
