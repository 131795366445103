<script>
    export default {
        beforeCreate() {
            let routeParams = this.$route.params
            const queryString = (window.location.href).includes('?') ? (window.location.href).split('?')[1] : '';
            if (queryString) {
                const urlParams = new URLSearchParams(queryString);
                if (urlParams.get('replaceObject') && urlParams.get('replaceId')) {
                    routeParams = {
                        for: urlParams.get('replaceObject'),
                        id: urlParams.get('replaceId'),
                        folder: 0,
                        source: [],
                        locale: this.$root.settings('locale'),
                    }
                }
            }

            this.$router.push({
                name: 'details',
                params: routeParams,
            })
        },
    }
</script>

<template>
    <div></div>
</template>
