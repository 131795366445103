<script>
    export default {
        props: {
            filter: {
                type: Object,
                default() {
                    return {}
                },
            },
            groupEdit: {
                type: Boolean,
                default: true,
            },
            groupBy: {
                type: String,
            },
            groupData: {
                type: String,
            },
            label: {
                type: String,
                default: '',
            },
        },
    }
</script>

<template>
    <bg-table view="re-details?for=System&amp;id=${Id}" :label="label" path="system" object="System" :group-by="groupBy"
              :group-data="groupData" :filter="filter" :state-view="true">

        <template v-slot="data">
            <bg-column :label="$t('Item number')" sort="SapId">{{ data.row.SapId }}</bg-column>
            <bg-column :label="$t('Name')" sort="Name">{{ $root.translation(data.row.SystemI18ns).Name }}</bg-column>
            <bg-column :label="$t('Edge')" sort="Edge" :visible="false">
                {{ data.row.Edge ? ($root.translation(data.row.Edge.EdgeI18ns).Name) : '-' }}
            </bg-column>
            <bg-column-files v-for="(folder, index) in data.row.CollectionType.Folders" :key="index"
                             :label="$root.translation(folder.FolderI18ns, {}, $root.Storage.get('settings').interfaceLanguage).Name" :collection="data.row"
                             :type="folder.Shortcut"/>
        </template>
        <template v-slot:group="data">
            <bg-table-group span="6" :label="$root.translation(data.row.ProductI18ns).Name"
                            :view="(groupEdit ? 're-details?for=Product&amp;id=${Id}' : '')" :data="data"
                            :isParentEditAllowed="$root.isReadAllowed('Product')"/>
        </template>

    </bg-table>
</template>
