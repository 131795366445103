<script>
import Card from '@/components/Card'

export default {
    components: {
        Card,
    },

    mounted() {
        this.$root.restrictCountries('*')
        this.$store.commit('mainPage', {
            current: this.$t('Components'),
            stack: [{
                'path': '/components',
                'title': this.$t('Components'),
            }]
        })
    },
}
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <card class="is-card-table has-pagination has-bottom-left-hidden">
                <bg-table-components group-by="ComponentTypeId" group-data="ComponentType" />
            </card>
        </div>
    </section>
</template>
