<script>
    export default {
        beforeCreate() {
            let routeParams = this.$route.query
            this.$router.push({
                name: 'media',
                query: routeParams,
            })
        },
    }
</script>

<template>
    <div></div>
</template>
