import Vue from 'vue'
import Router from 'vue-router'
import Tags from './views/Tags.vue'
import Users from './views/Users.vue'
import Media from './views/Media.vue'
import Create from './views/Create.vue'
import Details from './views/Details.vue'
import Folders from './views/Folders.vue'
import Systems from './views/Systems.vue'
import Components from './views/Components.vue'
import SubBrands from './views/SubBrands.vue'
import Collection from './views/Collection.vue'
import NewMedia from './views/NewMedia.vue'
import NewDetails from './views/NewDetails.vue'
import ProductFamilies from './views/ProductFamilies.vue'
import Admin from './views/Admin.vue'
import StatusTool from './views/StatusTool.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            redirect: 'sub-brands',
        },
        {
            path: '/sub-brands',
            name: 'sub-brands',
            component: SubBrands
        },
        {
            path: '/details',
            name: 'details',
            component: Details
        },
        {
            path: '/re-details',
            name: 're-details',
            component: NewDetails
        },
        {
            path: '/create',
            name: 'create',
            component: Create
        },
        {
            path: '/product-families',
            name: 'product-families',
            component: ProductFamilies
        },
        {
            path: '/systems',
            name: 'systems',
            component: Systems
        },
        {
            path: '/components',
            name: 'components',
            component: Components
        },
        {
            path: '/collection',
            name: 'collection',
            component: Collection
        },
        {
            path: '/media',
            name: 'media',
            component: Media
        },
        {
            path: '/re-media',
            name: 're-media',
            component: NewMedia
        },
        {
            path: '/folders',
            name: 'folders',
            component: Folders
        },
        {
            path: '/tags',
            name: 'tags',
            component: Tags
        },
        {
            path: '/users',
            name: 'users',
            component: Users
        },
        {
            path: '/admin',
            name: 'admin',
            component: Admin
        },
        {
            path: '/status-tool',
            name: 'status-tool',
            component: StatusTool
        },
        {
            path: 'full-page',
            component: () => import(/* webpackChunkName: "full-page" */ './views/FullPage.vue'),
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Login.vue')
                }
            ]
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

