<template>
    <div class="control">
        <input ref="datePicker"
               :name="fieldName"
               class="input"
               v-model="date"
               type="text"
               autocomplete="off"
               :disabled="disabled"
               :placeholder="placeholder"
               @input="onInput">
    </div>
</template>

<script>
import flatpickr from "flatpickr";

export default {
    name: "DatePicker",
    props: {
        fieldName: {
            type: String,
            default: 'date',
        },
        value: {
            type: String,
            default: null,
        },
        enableTime: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minDate: {
            default: null,
        },
        maxDate: {
            default: null,
        },
        format: {
            ype: String,
            default: 'n/d/y',
        },
        placeholder: {
            ype: String,
            default: '',
        }
    },
    data() {
        return {
            fp: null,
            date: null,
        }
    },
    mounted() {
        this.$nextTick(() => {
            let dateFormat = this.format
            if (this.enableTime)
                dateFormat += ' h:i K'

            this.fp = flatpickr(this.$refs.datePicker, {
                enableTime: this.enableTime,
                dateFormat: dateFormat,
                defaultDate: this.value,
                minDate: this.minDate,
                maxDate: this.maxDate,
            });
        })
    },
    methods: {
        onInput() {
            this.$emit('input', this.date)
        }
    },
    watch: {
        value(newValue) {
            if (this.fp)
                this.fp.setDate(newValue)
        }
    }
}
</script>
