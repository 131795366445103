<script>
    export default {
        props: {
            properties: {
                type: Array,
                required: true,
            },
            label: {
                type: String,
                default: 'auto',
            },
            type: {
                type: String,
                default: undefined,
            },
            max: {
                type: Number,
                default: 4,
            },
            sort: {
                type: String,
                default: '',
            },
            render: {
                type: Function,
            },
        },

        data() {
            return {
                isLinearList: false,
                internProperties: [],
                property: {
                    id: undefined,
                    state: undefined,
                    type: undefined,
                },
                display: {
                    propertyUnlimited: {
                        state: false,
                        label: 'Alle Einträge anzeigen (bgrz. ' + this.max + ')',
                    }
                },
            }
        },

        computed: {
            renderProperties: {
                set: function (value) {
                    this.internProperties = value
                },

                get: function () {
                    let resultSet = []

                    this.internProperties.forEach(propertyValue => {
                        if (resultSet.length < this.max || this.display.propertyUnlimited.state) {
                            if (typeof this.render !== 'undefined') {
                                resultSet.push(this.render(propertyValue))
                            } else {
                                if (propertyValue.VValue !== null) {
                                    resultSet.push(propertyValue.VValue)
                                } else {
                                    resultSet.push(propertyValue.VText)
                                }
                            }
                        }
                    })

                    return resultSet
                }
            },
        },

        mounted() {
            let component2property = this.$root.findProperty(this.properties, this.type)
            this.isLinearList = false

            if (typeof component2property !== 'undefined') {
                let propertyValues = component2property.Property.PropertyValues
                let component2propertySecond = this.$root.findProperty(this.properties, this.type + '2')
                if (typeof component2propertySecond !== 'undefined') {
                    this.isLinearList = true
                    propertyValues = [...propertyValues, ...component2propertySecond.Property.PropertyValues]
                }

                this.property.id = component2property.Property.Id
                this.property.state = component2property.Property.StateId
                this.property.type = component2property.Property.PropertyType.PropertyKey
                this.renderProperties = propertyValues
            }
        },
    }
</script>

<template>
    <bg-column-list :label="label" :sort="sort" :list="renderProperties" :is-linear-list="isLinearList"/>
</template>
