<script>
    import TabDetailUser from '@/views/Details/User'
    import TabDetailFolder from '@/views/Details/Folder'

    export default {
        components: {
            TabDetailUser,
            TabDetailFolder,
        },

        data() {
            return {
                activeTab: 0,
                fetched: {},
                isFetched: false,
                routeTable: {
                    User: {
                        collectionTypeId: 0,
                        route: 'users',
                        label: this.$t('Users'),
                        tabs: ['details'],
                    },
                    Folder: {
                        collectionTypeId: 4,
                        route: 'folders',
                        label: this.$t('Folders'),
                        tabs: ['details'],
                    },
                },
            }
        },

        async created() {
            if (this.param('for') == null) {
                let route = (this.param('for') in this.routeTable ? this.routeTable[this.param('for')].route : 'sub-brands')
                this.$router.push({name: route})
            }

            this.pushToRouter()
        },

        methods: {
            /**
             * Returns the GET param
             */
            param(key) {
                return this.$route.query[key] || null
            },

            /**
             * RenderView
             */
            view(name) {
                return this.param('for') == name
            },

            redirect() {
                let route = (this.param('for') in this.routeTable ? this.routeTable[this.param('for')].route : 'sub-brands')
                this.$router.push({name: route})
            },

            /**
             * Returns the GET param
             */
            pushToRouter() {
                this.$store.commit('mainPage', {
                    current: this.$t('Create new'),
                    stack: [{
                        'path': this.routeTable[this.param('for')].route,
                        'title': this.routeTable[this.param('for')].label,
                    }]
                })

                this.isFetched = true
            },
        },
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <div class="b-tabs">
                <nav class="tabs is-boxed">
                    <ul>
                        <li class="is-active">
                            <a><span>{{ $t('Create new') }}</span></a>
                        </li>
                    </ul>
                </nav>

                <section class="tab-content card is-card-table is-card-detail">
                    <div class="tab-item" style="" v-if="isFetched">
                        <tab-detail-folder v-if="view('Folder')" :create="true" @afterSend="redirect"/>
                        <tab-detail-user v-else-if="view('User')" :create="true" @afterSend="redirect"/>
                        <div v-else>{{ fetched }}</div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>
