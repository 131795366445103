<script>
    export default {
        props: {
            filter: {
                type: Object,
                default() {
                    return {}
                },
            },
            groupEdit: {
                type: Boolean,
                default: true,
            },
            groupBy: {
                type: String,
            },
            groupData: {
                type: String,
            },
            label: {
                type: String,
                default: '',
            },
        },

        methods: {
            renderValueAndUnit(propertyValue) {
                let value = propertyValue.VValue
                if (typeof propertyValue.Unit !== 'undefined' && propertyValue.Unit) {
                    value += ' ' + this.$root.translation(propertyValue.Unit.UnitI18ns).Name
                }
                return value
            },

            renderBoolean(propertyValue) {
                return propertyValue.VValue === 1 ? this.$t('Yes') : this.$t('No')
            },
        },
    }
</script>

<template>
    <bg-table view="re-details?for=Component&amp;id=${Id}" :label="label" path="component" object="Component"
              group-by="ComponentTypeId" group-data="ComponentType" :filter="filter" :state-view="true">

        <template v-slot="data">
            <bg-column :label="$t('Item number')" sort="SapId">{{ data.row.SapId }}</bg-column>
            <bg-column :label="$t('Name')">{{ $root.translation(data.row.ComponentI18ns).Name | truncate(50) }}</bg-column>
            <bg-column :label="$t('Edge')" sort="Edge" :visible="false">
                {{ data.row.Edge ? ($root.translation(data.row.Edge.EdgeI18ns).Name) : '-' }}
            </bg-column>
            <bg-column-property
                    :label="$t('Weight')"
                    :properties="data.row.Component2Properties"
                    type="weight"
                    :render="renderValueAndUnit"
            />
            <bg-column-property
                    :label="$t('Length')"
                    :properties="data.row.Component2Properties"
                    type="length"
                    :render="renderValueAndUnit"
            />
            <bg-column-property
                    :label="$t('Height')"
                    :properties="data.row.Component2Properties"
                    type="height"
                    :render="renderValueAndUnit"
            />
            <bg-column-property
                    :label="$t('Width')"
                    :properties="data.row.Component2Properties"
                    type="width"
                    :render="renderValueAndUnit"
            />
            <bg-column-property
                    :label="$t('Quantity per package')"
                    :properties="data.row.Component2Properties"
                    type="quantity_per_package"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Package unit')"
                    :properties="data.row.Component2Properties"
                    type="package_unit"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Quantity unit')"
                    :properties="data.row.Component2Properties"
                    type="quantity_unit"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Feed cross section')"
                    :properties="data.row.Component2Properties"
                    type="feed_cross_section"
                    :render="renderValueAndUnit"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Laying radius')"
                    :properties="data.row.Component2Properties"
                    type="laying_radius"
                    :render="renderValueAndUnit"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Ecological balance')"
                    :properties="data.row.Component2Properties"
                    type="ecological_balance"
                    :render="renderBoolean"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('CO2-balance')"
                    :properties="data.row.Component2Properties"
                    type="co2_balance"
                    :render="renderValueAndUnit"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Color')"
                    :properties="data.row.Component2Properties"
                    type="color"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Gradient')"
                    :properties="data.row.Component2Properties"
                    type="gradient"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Number')"
                    :properties="data.row.Component2Properties"
                    type="number"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Load class')"
                    :properties="data.row.Component2Properties"
                    type="load_class"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Designation')"
                    :properties="data.row.Component2Properties"
                    type="designation"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Closure')"
                    :properties="data.row.Component2Properties"
                    type="closure"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Material')"
                    :properties="data.row.Component2Properties"
                    type="material"
                    :visible="false"
            />
            <bg-column-property
                    :label="$t('Mesh')"
                    :properties="data.row.Component2Properties"
                    type="mesh"
                    :visible="false"
            />
            <bg-column-files v-for="(folder, index) in data.row.CollectionType.Folders" :key="index"
                             :label="$root.translation(folder.FolderI18ns, {}, $root.Storage.get('settings').interfaceLanguage).Name" :collection="data.row"
                             :type="folder.Shortcut"/>
        </template>

        <template v-slot:group="data">
            <bg-table-group span="5" :label="$root.translation(data.row.ComponentTypeI18ns, {}, $root.settings('locale')).Name"
                            :data="data"/>
        </template>

    </bg-table>
</template>
