<script>
    export default {
        props: {
            filter: {
                type: Object,
                default () {
                    return {}
                },
            },
            fileId: {
                type: Number,
                required: true,
            },
            relationObject: {
                type: String,
                required: true,
            },
            relationLabel: {
                type: String,
                required: true,
            },
            relationTab: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                sendingData: false
            }
        },

        mounted() {
        },

        methods: {
            /**
             * Returns the GET param
             */
            param(key) {
                return (key in this.$route.params)
                    ? this.$route.params[key]
                    : null
            },

            clickRowApply(fkId) {
                let objectIdName = this.relationObject + 'Id';
                this.$root.post(this.relationTab + '-2-file', {
                    [objectIdName]: fkId,
                    'FileId': this.fileId
                }).then(() => {
                    this.$parent.$parent.$parent.reloadContents()
                })
            },
        },
    }
</script>

<template>
    <div style="position: relative">
        <div v-if="sendingData" class="bg-upload__sync"></div>

        <div class="level">
            <div class="level-left">
                <p class="is-size-5">
                    <strong>Verknüpfung zu {{ relationLabel }}</strong>
                </p>
            </div>
            <div class="level-right"></div>
        </div>

        <div v-if="relationObject === 'Group'">
            <bg-table :path="relationTab" :object="relationObject" :filter="filter">

                <template v-slot="data">
                    <bg-column :label="$t('ID')" sort="Id">{{ data.row.Id }}</bg-column>
                    <bg-column :label="$t('Item number')" sort="SapId">{{ data.row.SapId }}</bg-column>
                    <bg-column :label="$t('Name')">{{ $root.translation(data.row[relationObject + 'I18ns']).Name | truncate(50) }}</bg-column>
                </template>

                <template v-slot:tools="data">
                    <button @click.prevent="clickRowApply(data.row.Id)" class="button is-success" type="button"
                            style="border-radius: 50%" v-if="$root.childObjectHasPropertyValue(data.row[relationObject + '2Files'], 'FileId', fileId)">
                        <b-icon icon="check" custom-size="default"/>
                    </button>
                </template>

            </bg-table>
        </div>

        <div v-if="relationObject !== 'Group'">
            <bg-table :path="relationTab" :object="relationObject" :group-by="`${relationObject}TypeId`" :group-data="`${relationObject}Type`" :filter="filter">

                <template v-slot="data">
                    <bg-column :label="$t('ID')" sort="Id">{{ data.row.Id }}</bg-column>
                    <bg-column :label="$t('Item number')" sort="SapId">{{ data.row.SapId }}</bg-column>
                    <bg-column :label="$t('Name')">{{ $root.translation(data.row[relationObject + 'I18ns']).Name | truncate(50) }}</bg-column>
                </template>

                <template v-slot:group="data">
                    <bg-table-group span="5" :label="$root.translation(data.row[relationObject + 'TypeI18ns'], {}, 'de_AT').Name" :data="data" />
                </template>

                <template v-slot:tools="data">
                    <button @click.prevent="clickRowApply(data.row.Id)" class="button is-success" type="button"
                            style="border-radius: 50%" v-if="$root.childObjectHasPropertyValue(data.row[relationObject + '2Files'], 'FileId', fileId)">
                        <b-icon icon="check" custom-size="default"/>
                    </button>
                </template>

            </bg-table>
        </div>

    </div>
</template>
