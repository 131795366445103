<template>
    <span v-if="propertyValue && typeof propertyValue.value !== 'undefined' && propertyValue.value">
        <span class="fly-on-hover">
            <span v-if="propertyValue.type === 'array' && propertyValue.value.length">{{ propertyValue.value.join(' | ') }}</span>
            <span v-else-if="propertyValue.type === 'objectArray'">
                <template v-for="(value, index) in propertyValue.value">
                    <span :key="index">
                        <span v-if="index">| </span><i class="mdi mdi-file"/> {{ value }}
                    </span>
                </template>
            </span>
            <span v-else-if="propertyValue.type === 'image'"><i class="mdi mdi-image-outline"/> {{ propertyValue.value }}</span>
            <span v-else-if="propertyValue.type === 'object'"><i class="mdi mdi-file"/> {{ propertyValue.value }}</span>
            <span v-else>{{ propertyValue.value }}</span>
        </span>
        <span class="show-on-hover">&nbsp;</span>
    </span>
    <span v-else>
        <span class="fly-on-hover">
            {{ propertyValue ? propertyValue : '&nbsp;' }}
        </span>
        <span class="show-on-hover">&nbsp;</span>
    </span>
</template>

<script>
    export default {
        name: "StatusToolProperty",
        props: ['propertyValue'],
    }
</script>
