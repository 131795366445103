<script>
    import BgSelectRelationModalView from '@/components/BgSelectRelationModalView'

    export default {
        components: {
            BgSelectRelationModalView: BgSelectRelationModalView,
        },

        props: {
            show: {
                type: Boolean,
                required: true,
            },
            fileId: {
                type: Number,
                required: true,
            },
            relationObject: {
                type: String,
                required: true,
            },
            relationLabel: {
                type: String,
                required: true,
            },
            relationTab: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                visible: false,
            }
        },

        watch: {
            show: function (newValue) {
                this.visible = newValue
            },
        },

        methods: {}
    }
</script>

<template>
    <b-modal @close="$emit('close')" :active.sync="visible" has-modal-card custom-class="bg-upload__modal">
        <div class="card">
            <div class="card-content">
                <div class="columns bg-upload__grid">
                    <div class="column">
                        <bg-select-relation-modal-view @search="value => $emit('search', value)" :fileId="fileId" :relationObject="relationObject" :relationLabel="relationLabel" :relationTab="relationTab"/>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
