<script>
    import BgSelectRelationModal from '@/components/BgSelectRelationModal'

    export default {
        components: {
            BgSelectRelationModal: BgSelectRelationModal,
        },

        props: {
            file: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                Id: 0,
                OriginalName: null,
                Name: null,
                Extension: null,
                relations: [],
                relationTab: 'group',
                relationObject: 'Group',
                relationLabel: 'Subbrand',
                columns: [
                    {
                        field: 'CollectionType',
                        label: this.$t('Type'),
                    },
                    {
                        field: 'SapId',
                        label: this.$t('Item number'),
                    },
                    {
                        field: 'ObjectName',
                        label: this.$t('Object name'),
                    }
                ],
                selectRelation: false,
            }
        },

        mounted() {
            this.Id = this.file.response.Id
            this.OriginalName = this.file.response.OriginalName
            this.Name = this.$root.translation(this.file.response.FileI18ns, {}).Name ? (this.$root.translation(this.file.response.FileI18ns, {}).Name + '.' + this.file.response.Extension) : this.OriginalName
            this.Extension = this.file.response.Extension
        },

        methods: {
            /**
             * Returns the GET param
             */
            param(key) {
                return (key in this.$route.params)
                    ? this.$route.params[key]
                    : null
            },

            reloadContents() {
                this.$root.findChild(this, 'BgTable', table => {
                    table.itemsLoading = true
                    table.fetch()
                    this.selectRelation = false
                })
            },

            clickRowRemove(rowData) {
                if (confirm(this.$t('Are you sure you want to remove this relation?'))) {
                    let objectIdName = this.relationObject + 'Id'
                    let objectIdFilter = 'filter[' + objectIdName + ']'
                    let objectId = rowData[objectIdName];
                    let params = {}
                    params[objectIdFilter] = objectId
                    params['filter[FileId]'] = rowData.FileId

                    this.$root.delete(this.relationTab + '-2-file', params).then(() => {
                        this.reloadContents()
                    })
                }
            },

            editRelation() {
                this.selectRelation = true
            },

            done() {
            },
        },
    }
</script>

<template>
    <div style="position: relative">
        <div class="level">
            <div class="level-left">
                <p class="is-size-5">
                    <strong>{{ $t('Relations for %CollectionType% (#%Id%)') | sprintf({'%CollectionType%': Name, '%Id%': Id}) }}</strong>
                </p>
            </div>
            <div class="level-right"></div>
        </div>

        <div class="b-tabs">
            <nav class="tabs is-boxed" style="margin: 0 -1.25rem">
                <ul style="padding: 0 1.25rem">
                    <li :class="{ 'is-active': relationTab == 'group' }">
                        <a @click.prevent="relationTab = 'group' ; relationObject = 'Group' ; relationLabel = 'Subbrand'"><span>{{ $t('Sub-brands') }}</span></a>
                    </li>
                    <li :class="{ 'is-active': relationTab == 'product' }">
                        <a @click.prevent="relationTab = 'product' ; relationObject = 'Product'; relationLabel = 'Produktfamilie'"><span>{{ $t('Products') }}</span></a>
                    </li>
                    <li :class="{ 'is-active': relationTab == 'system' }">
                        <a @click.prevent="relationTab = 'system' ; relationObject = 'System'; relationLabel = 'System'"><span>{{ $t('Systems') }}</span></a>
                    </li>
                    <li :class="{ 'is-active': relationTab == 'component' }">
                        <a @click.prevent="relationTab = 'component' ; relationObject = 'Component'; relationLabel = 'Komponente'"><span>{{ $t('Components') }}</span></a>
                    </li>
                </ul>
            </nav>

            <section style="maring: 0 -0.75rem; padding: 1rem 0 0">
                <bg-table :path="`${relationTab}-2-file?filter[FileId]=${file.response.Id}`"
                          :object="`${relationObject}2File`">

                    <template v-slot="data">
                        <bg-column :label="$t('ID')" sort="Name">{{ data.row[relationObject].Id }}</bg-column>
                        <bg-column :label="$t('Item number')" sort="Name">
                            {{ data.row[relationObject].SapId }}
                        </bg-column>
                        <bg-column :label="$t('Name')" sort="Name">
                            {{ data.row[relationObject][relationObject + 'I18ns'][0].Name }}
                        </bg-column>
                    </template>

                    <template v-slot:tools="data">
                        <button @click.prevent="clickRowRemove(data.row)" class="button is-success" type="button"
                                style="border-radius: 50%">
                            <b-icon icon="delete" custom-size="default"/>
                        </button>
                    </template>

                </bg-table>
            </section>

            <div style="margin: 1.25rem 0 0">
                <div class="level">
                    <div class="level-left"></div>
                    <div class="level-right">
                        <button @click.prevent="editRelation()" class="button is-primary">
                            {{ $t('New %CollectionName% relation') | sprintf({'%CollectionName%': relationLabel}) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <bg-select-relation-modal @close="selectRelation = false" :show="selectRelation" :fileId="Id"
                                  :relationObject="relationObject" :relationLabel="relationLabel"
                                  :relationTab="relationTab"/>

    </div>
</template>
