<template>
    <div class="card" v-bind="$attrs">
        <header v-if="hasHeader" class="card-header">
            <p class="card-header-title">
                <b-icon v-if="icon" :icon="icon" custom-size="default"/>
                <span v-if="title">{{ title }}</span>
            </p>
            <div v-if="hasButton" class="card-header-icon has-button">
                <slot name="button"></slot>
            </div>
        </header>
        <slot name="toolbar"/>
        <div ref="cardContent" class="card-content" :class="{'has-height-medium':hasHeightMedium}">
            <slot/>
        </div>
        <slot name="bottom-toolbar"/>
        <footer v-if="hasFooter" class="card-footer">
            <slot name="footer"/>
        </footer>
    </div>
</template>

<script>
  import PerfectScrollbar from 'perfect-scrollbar'

  export default {
    name: "Card",
    data() {
      return {
        ps: null,
      }
    },
    props: {
      icon: {
        type: String,
        default: null,
      },
      title: {
        type: String,
        default: null,
      },
      hasHeightMedium: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      hasFooter() {
        return !!this.$slots['footer'];
      },
      hasButton() {
        return !!this.$slots['button'];
      },
      hasHeader() {
        return (this.icon || this.title || this.hasButton);
      }
    },
    mounted() {
      if (this.hasHeightMedium) {
        this.ps = new PerfectScrollbar(this.$refs.cardContent)
        this.$emit('ps-done', this.ps)
      }

    }
  }
</script>

<style scoped>

</style>