<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            list: {
                type: Array,
                required: true,
            },
            sort: {
                type: String,
                default: '',
            },
            isLinearList: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<template>
    <bg-column-wrapper :label="label" :sort="sort">
        <ul class="bg-column--list" v-if="list.length > 1 && !isLinearList">
            <li v-for="(value, index) in list" :key="index" class="bg-column--list__item">
                {{ value }}
            </li>
        </ul>
        <span style="white-space: nowrap" v-if="list.length > 1 && isLinearList">
            {{ list.join(' | ') }}
        </span>
        <span v-if="list.length === 1">{{ list[0] }}</span>
        <span v-if="list.length === 0">-</span>
    </bg-column-wrapper>
</template>
