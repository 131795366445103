<script>
    import Card from '@/components/Card'

    export default {
        components: {
            Card,
        },

        mounted() {
            this.$root.restrictCountries('*')
            this.$store.commit('mainPage', {
                current: this.$t('Collection'),
                stack: [{
                    'path': '/collection',
                    'title': this.$t('Collection'),
                }]
            })
        },
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <card class="is-card-table has-pagination has-bottom-left-hidden">
                <bg-table-collections :label="$t('Collection')"/>
            </card>
        </div>
    </section>
</template>
