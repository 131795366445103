<script>
    export default {
        props: {
            filter: {
                type: Object,
                default() {
                    return {}
                },
            },
            label: {
                type: String,
                default: '',
            },
        },
    }
</script>

<template>
    <bg-table view="re-details?for=Group&amp;id=${Id}" :label="label" path="group" object="Group" :filter="filter"
              v-slot="data" :state-view="true">
        <bg-column :label="$t('Name')" sort="Name">{{ data.row.GroupI18ns[0].Name }}</bg-column>
        <bg-column-property :label="$t('Technology')" :properties="data.row.Group2Properties" type="technology"/>
        <bg-column-property :label="$t('Material')" :properties="data.row.Group2Properties" type="material"/>
        <bg-column-files v-for="(folder, index) in data.row.CollectionType.Folders" :key="index"
                         :label="$root.translation(folder.FolderI18ns, {}, $root.Storage.get('settings').interfaceLanguage).Name" :collection="data.row"
                         :type="folder.Shortcut"/>
    </bg-table>
</template>
