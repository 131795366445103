<script>
export default {
    props: {
        fetched: {},
        create: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            UserRole: [],
        }
    },

    async created () {
        await this.$root.fetch('user-role').then(response => {
            this.UserRole = response.UserRole
        })
    },

    methods: {
        beforeSend(content, call) {
            return [content, this.$root.stringPlaceholders(call, content)]
        },
    },
}
</script>

<template>
    <div>
        <bg-form v-if="!create" button="Änderungen speichern (Erneuter Login erforderlich)" :beforeSend="'beforeSend'" :call="'user/' + fetched.Id" @afterSend="$root.logoutUser()">
            <bg-form-input :label="$t('First name')"  name="FirstName" :size="3" :prefill="fetched.FirstName" />
            <bg-form-input :label="$t('Last name')" name="LastName" :size="3" :prefill="fetched.LastName" />
            <bg-form-input :label="$t('Email')"   name="Email" :size="6" :prefill="fetched.Email" />
            <bg-form-input :label="$t('Username')" :size="6" :prefill="fetched.Username" />
            <bg-form-input :label="$t('Password (min. 5 characters)')" name="Password" :password="true" :size="6" />
        </bg-form>
    </div>
</template>