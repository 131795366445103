<script>
    export default {
        props: {
            collection: {
                type: Object,
                required: true,
            },
            label: {
                type: String,
                default: 'auto',
            },
            type: {
                type: String,
                required: true,
            },
            sort: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                counter: {
                    Id: null,
                    Count: null,
                    StateId: null,
                    Shortcut: null,
                    RequiredCount: null,
                    FolderI18nsName: null,
                    CollectionTypeId: null,
                },
                display: {
                    filesDebug: {
                        state: false,
                        permission: ['admin'],
                        label: this.$t('Debug (BgColumnEdited)'),
                    },
                },
            }
        },

        mounted() {
            let folder = this.$root.findFolder(this.collection, this.type)

            if (typeof folder !== 'undefined') {
                this.counter.CollectionTypeId = folder.CollectionTypeId
                this.counter.Count = folder.Count
                this.counter.StateId = folder.StateId
                this.counter.Id = folder.Id
                this.counter.RequiredCount = folder.RequiredCount
                this.counter.Shortcut = folder.Shortcut
                this.counter.FolderI18nsName = this.$root.translation(folder.FolderI18ns).Name
            }
        },
    }
</script>

<template>
    <bg-column-progress :label="label" :sort="sort" :value="counter.Count" :required="counter.RequiredCount">
        <div v-if="display.filesDebug.state">
            <ul>
                <li v-for="(value, key) in counter" :key="key"><strong>{{ key }}:</strong> {{ value }}</li>
            </ul>
        </div>
    </bg-column-progress>
</template>
