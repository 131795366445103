<template>
    <ul :class="{'menu-list':!isSubmenuList}">
        <aside-menu-item v-for="(item,index) in menuList"
                         :hasTooltip="hasTooltips"
                         @menu-click="menuClick"
                         :item="item"
                         :key="indexKeyPrefix + '-' + index" :indexKey="indexKeyPrefix + '-' + index"
                         :isUpdateByGlobalActiveState="isUpdateByGlobalActiveState"
                         :hasDropdownEnabled="hasDropdownEnabled"/>
    </ul>
</template>

<script>
    import AsideMenuItem from '@/components/AsideMenuItem'

    export default {
        name: "AsideMenuListPlain",
        components: {
            AsideMenuItem
        },
        props: {
            isSubmenuList: {
                type: Boolean,
                default: false,
            },
            menuList: {
                type: Array,
                default: () => [],
            },
            hasDropdownEnabled: {
                type: Boolean,
                default: false,
            },
            hasTooltips: {
                type: Boolean,
                default: false,
            },
            isUpdateByGlobalActiveState: {
                type: Boolean,
                default: false,
            },
            indexKeyPrefix: {
                type: String,
                default: null,
            }
        },
        methods: {
            menuClick(item) {
                this.$emit('menu-click', item)
            }
        }
    }
</script>
