<template>
  <div @mouseleave="mouseLeave" @mouseenter="mouseEnter" class="navbar-item has-dropdown has-dropdown-with-icons" :class="{ 'is-hoverable':isNavBarMenusHoverable, 'is-active':isDropdownActive }">
    <a @click="toggle" class="navbar-link is-arrowless">
      <slot></slot>
      <b-icon :icon="toggleDropdownIcon" custom-size="default"/>
    </a>

    <slot name="dropdown"></slot>

  </div>
</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: "NavBarMenu",
    data() {
      return {
        isDropdownActive: false,
        isMouseIn: null,
        timeoutId: null,
      }
    },
    computed: {
      toggleDropdownIcon() {
        return this.isDropdownActive?'chevron-up':'chevron-down'
      },
      ...mapState([
        'isNavBarMenusHoverable',
        'navBarDropdownsForcedClose'
      ])
    },
    methods: {
      toggle() {
        if (!this.isNavBarMenusHoverable) {
          this.$store.commit('basic', {
            key: 'navBarDropdownsForcedClose',
            value: this.$options._scopeId + '-' + this._uid
          })

          this.isDropdownActive = !this.isDropdownActive
        }
      },
      mouseLeave() {
        if (this.isDropdownActive) {
          this.timeoutId = setTimeout(() => {
            this.isDropdownActive = false
          }, 300)
        }
      },
      mouseEnter() {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
        }
      }
    },
    watch: {
      isNavBarMenusHoverable() {
        this.isDropdownActive = false
      },
      navBarDropdownsForcedClose(newVal) {
        if (newVal !== this.$options._scopeId + '-' + this._uid)
          this.isDropdownActive = false
      }
    }
  }
</script>

<style scoped>

</style>