<template>
    <table :class="{'bg-status-tool__table': true, 'compact': isCompact, 'bg-status-tool__table--main': isSticky}">
        <thead v-if="page.isMain">
        <tr>
            <td colspan="6">{{ isCompact ? locale : headingLong }}</td>
        </tr>
        </thead>
        <tbody>
        <tr class="bg-status-tool-spacer" v-if="page.isMain">
            <td colspan="6"></td>
        </tr>
        <tr :class="{'bg-status-tool-page': true, 'hovered': hoveredRow === page.identifier, 'clicked': clickedRows.includes(page.identifier)}"
            v-if="matchesFilter(page.properties, page.identifier, true)"
            :rel="page.identifier"
            @click="changeClickedRows(page.identifier)"
            @mouseenter="handleRowHover(page.identifier)"
            @mouseleave="handleRowHover('')">
            <td class="bg-status-tool__table-icon" v-for="index in level" :key="index"></td>
            <td :class="'bg-status-tool__table-icon big ' + (typeof page.children !== 'undefined' && Object.keys(page.children).length ? 'green' : 'transparent')">
                <i v-if="displayType === 'pages'" :class="'mdi mdi-menu-' + (closedPages.includes(page.identifier) ? 'up' : 'down')" @click="handlePageExpand(page.identifier)" style="cursor:pointer;"></i>
            </td>
            <td class="bg-status-tool__table-icon" :class="'bg-status-tool__table-icon' + (hasTitle(page) ? '' : ' empty')">
                <i :class="'mdi mdi-' + getIconByNodeType(page.nodetype)"></i>
            </td>
            <td class="bg-status-tool-page__title">
                <span v-if="!isCompact">
                    {{ hasTitle(page) ? page.properties.title : '&nbsp;' }}
                </span>
                <span v-else>&nbsp;</span>
            </td>
            <td :class="'bg-status-tool__table-icon bg-status-tool__table-icon--hidden ' + (page.hidden ? 'red' : 'transparent')">
                <i class="mdi mdi-eye-off" :title="$t('Deactivated')"></i>
            </td>
            <td class="bg-status-tool__table-icon green">
                <i :class="'mdi mdi-' + getStateIconByProperties(page.properties)"
                   :title="getStateLabelByProperties(page.properties)"
                   v-if="hasTitle(page)"></i>
            </td>
            <td :id="'info-' + page.identifier"
                class="bg-status-tool__table-icon bg-status-tool__table-icon--info grey"
                @mouseenter="setIdentifierInfo(page.identifier, !hasTitle(page))"
                @mouseleave="setIdentifierInfo('', !hasTitle(page))">
                <i class="mdi mdi-information" v-if="displayType === 'pages' && hasTitle(page)"></i>
                <div v-if="displayType === 'pages' && !(!identifierInfo || identifierInfo !== page.identifier)" :class="'bg-status-tool-info ' + positionInfo">
                    <div class="bg-status-tool-info__button">
                        <a class="button is-success" style="width:100%;border-radius:4px;" :href="getUrlForNeos(page.path)" target="_blank">
                            {{ $t('Open in NEOS') }}
                        </a>
                    </div>
                    <div class="bg-status-tool-info__content" v-if="displayType === 'pages' && typeof page.properties !== 'undefined' && Object.keys(page.properties).length">
                        <div class="bg-status-tool-info__table">
                            <div class="bg-status-tool-info__table-row" v-for="(propertyValue, key) in page.properties" :key="key">
                                <div class="bg-status-tool-info__table-cell">
                                    <strong>{{ getPropertyLabel(key, page.originNodetype) }}</strong>
                                </div>
                                <div class="bg-status-tool-info__table-cell bg-status-tool-properties">
                                    <status-tool-property :property-value="propertyValue"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <template v-if="displayType === 'pages' && typeof page.children !== 'undefined' && Object.keys(page.children).length && !closedPages.includes(page.identifier)">
            <tr class="bg-status-tool-children" v-for="(child, identifier) in page.children" :key="identifier">
                <td :colspan="6 + level">
                    <status-tool-page-table :page="child"
                                            :level="level + 1"
                                            @change-expand="handlePageExpand"
                                            @click-row="changeClickedRows"
                                            @row-hover="handleRowHover"
                                            @show-identifier="showIdentifier"
                                            @hide-identifier="hideIdentifier"
                                            :closed-pages="closedPages"
                                            :clicked-rows="clickedRows"
                                            :hovered-row="hoveredRow"
                                            :filters="filters"
                                            :hidden-identifiers="hiddenIdentifiers"
                                            :filter-exists="filterExists"
                                            :filter-apply="filterApply"
                                            :display-type="displayType"
                                            :neos-username="neosUsername"
                                            :is-compact="isCompact"
                                            :neos-url="neosUrl"
                                            :locale="locale"
                                            :property-labels="propertyLabels"/>
                </td>
            </tr>
        </template>
        <template v-if="displayType === 'page-properties' && Object.keys(page.properties).length">
            <template v-for="(propertyValue, propertyKey) in page.properties">
                <tr :class="{'bg-status-tool-property-value': true, 'hovered': hoveredRow === (propertyKey + '-' + page.identifier), 'clicked': clickedRows.includes(propertyKey + '-' + page.identifier)}"
                    @mouseenter="handleRowHover(propertyKey + '-' + page.identifier)" @mouseleave="handleRowHover('')"
                    v-if="matchesPropertiesFilter(propertyKey, propertyValue, page.originNodetype, page.identifier) || matchesFilter(getSinglePropertyObject(propertyKey, propertyValue), propertyKey, false)"
                    :key="propertyKey + '-' + page.identifier">
                    <td colspan="6" class="bg-status-tool-properties" :data-rel="propertyKey">
                        <status-tool-property :property-value="propertyValue"/>
                    </td>
                </tr>
            </template>
        </template>
        <template v-if="displayType === 'page-properties' && Object.keys(page.children).length">
            <template v-for="(child, index, identifier) in page.children">
                <tr class="bg-status-tool-spacer" :key="'spacer-' + identifier">
                    <td colspan="6"></td>
                </tr>
                <tr class="bg-status-tool-page" :rel="child.identifier" :key="'title-' + identifier">
                    <td class="bg-status-tool__table-icon" v-for="lIndex in level" :key="lIndex"></td>
                    <td class="bg-status-tool__table-icon big transparent"></td>
                    <td class="bg-status-tool__table-icon" :class="'bg-status-tool__table-icon' + (hasTitle(child) ? '' : ' empty')">
                        <i :class="'mdi mdi-' + getIconByNodeType(child.nodetype)"></i>
                    </td>
                    <td class="bg-status-tool-page__title">
                        <span>
                            {{ hasTitle(child) ? child.properties.title : '&nbsp;' }}
                        </span>
                    </td>
                    <td :class="'bg-status-tool__table-icon bg-status-tool__table-icon--hidden ' + (child.hidden ? 'red' : 'transparent')">
                        <i class="mdi mdi-eye-off" :title="$t('Deactivated')"></i>
                    </td>
                    <td class="bg-status-tool__table-icon green">
                        <i :class="'mdi mdi-' + getStateIconByProperties(child.properties)"
                           :title="getStateLabelByProperties(child.properties)"
                           v-if="hasTitle(child)"></i>
                    </td>
                    <td :id="'info-' + child.identifier" class="bg-status-tool__table-icon bg-status-tool__table-icon--info grey"></td>
                </tr>
                <template v-for="(propertyValue, propertyKey) in child.properties">
                    <tr :class="{'bg-status-tool-property-value': true, 'hovered': hoveredRow === (propertyKey + '-' + child.identifier), 'clicked': clickedRows.includes(propertyKey + '-' + child.identifier)}"
                        @mouseenter="handleRowHover(propertyKey + '-' + child.identifier)" @mouseleave="handleRowHover('')"
                        v-if="matchesPropertiesFilter(propertyKey, propertyValue, child.originNodetype, child.identifier) || matchesFilter(getSinglePropertyObject(propertyKey, propertyValue), propertyKey, false)"
                        :key="propertyKey + '-' + child.identifier">
                        <td colspan="6" class="bg-status-tool-properties" :data-rel="propertyKey">
                            <status-tool-property :property-value="propertyValue"/>
                        </td>
                    </tr>
                </template>
            </template>
        </template>
        </tbody>
    </table>
</template>

<script>
    import StatusToolProperty from "@/components/StatusToolProperty.vue";

    export default {
        name: "StatusToolPageTable",
        components: {StatusToolProperty},
        props: {
            headingLong: {
                type: String,
                default: '',
            },
            locale: {
                type: String,
                default: '',
            },
            neosUsername: {
                type: String,
                default: '',
            },
            hoveredRow: {
                type: String,
                default: '',
            },
            neosUrl: {
                type: String,
            },
            displayType: {
                type: String,
            },
            page: {
                type: Object,
            },
            propertyLabels: {
                type: Object,
            },
            filters: {
                type: Object,
            },
            filterExists: {
                type: Boolean,
            },
            filterApply: {
                type: Boolean,
            },
            closedPages: {
                type: Array,
            },
            clickedRows: {
                type: Array,
            },
            hiddenIdentifiers: {
                type: Array,
            },
            level: {
                type: Number,
                default: 0,
            },
            isCompact: {
                type: Boolean,
            },
            isSticky: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                identifierInfo: '',
                positionInfo: 'top',
            };
        },
        methods: {
            hasTitle(page) {
                return typeof page.properties !== 'undefined' && Object.keys(page.properties).length && page.properties.title
            },
            getIconByNodeType(nodeType) {
                const iconMapper = {
                    'Shortcut': 'share',
                    'Folder': 'folder-outline',
                    'EmailTemplate': 'email-outline',
                    'HomePage': 'web',
                };

                return typeof iconMapper[nodeType] !== 'undefined' ? iconMapper[nodeType] : 'file-outline'
            },
            getStateIconByProperties(properties) {
                if (typeof properties !== 'undefined') {
                    if (typeof properties.isIndividual !== 'undefined' && properties.isIndividual === 'individualElement') {
                        return 'creation';
                    }
                    if (typeof properties.isGlobal !== 'undefined' && properties.isGlobal === 'globalElement') {
                        return 'content-copy';
                    }
                }

                return 'translate';
            },
            getStateLabelByProperties(properties) {
                if (typeof properties !== 'undefined') {
                    if (typeof properties.isIndividual !== 'undefined' && properties.isIndividual === 'individualElement') {
                        return this.$t('Individualized');
                    }
                    if (typeof properties.isGlobal !== 'undefined' && properties.isGlobal === 'globalElement') {
                        return this.$t('Mirrored');
                    }
                }

                return this.$t('Translated');
            },
            handlePageExpand(identifier) {
                this.$emit('change-expand', identifier)
            },
            changeClickedRows(identifier) {
                this.$emit('click-row', identifier)
            },
            handleRowHover(identifier) {
                this.$emit('row-hover', identifier)
            },
            getElementTopPositionInPercent(element, container) {
                // Get the bounding rectangle of the element
                const elementRect = element.getBoundingClientRect();
                // Get the bounding rectangle of the container
                const containerRect = container.getBoundingClientRect();
                // Calculate the position of the element relative to the container's viewport
                const elementTop = elementRect.top - containerRect.top;
                // Calculate the height and width of the container's viewport
                const containerHeight = containerRect.height;
                // Calculate the position of the element as a percentage of the container's viewport size
                return (elementTop / containerHeight) * 100;
            },
            setIdentifierInfo(identifier, preventAction) {
                if (!preventAction) {
                    this.identifierInfo = identifier
                }

                if (identifier) {
                    const info = document.getElementById('info-' + identifier)
                    const container = document.querySelector('.bg-status-tool__wrapper')
                    if (info) {
                        const positionTop = this.getElementTopPositionInPercent(info, container)
                        if (positionTop < 35) {
                            this.positionInfo = 'top'
                        } else if (positionTop > 60) {
                            this.positionInfo = 'bottom'
                        } else {
                            this.positionInfo = 'middle'
                        }
                    }
                }
            },
            getUrlForNeos(path) {
                return this.neosUrl + path + '@user-' + this.neosUsername + ';language=' + this.locale;
            },
            matchesFilter(properties, identifier, skipProperties) {
                if (skipProperties && this.displayType === 'page-properties') {
                    return true;
                }

                if (!this.filterApply) {
                    return !this.hiddenIdentifiers.length || !this.hiddenIdentifiers.includes(identifier);
                }

                if (!this.filterExists) {
                    this.showIdentifier(identifier)
                    return true
                }

                let matchFound;
                if (typeof properties === 'undefined') {
                    this.hideIdentifier(identifier)
                    return false
                }

                matchFound = this.matchesAnyFilter(this.filters, properties)
                if (matchFound) {
                    this.showIdentifier(identifier)
                } else {
                    this.hideIdentifier(identifier)
                }

                return matchFound
            },
            getSinglePropertyObject(propertyKey, propertyValue) {
                let properties = {};
                properties[propertyKey] = propertyValue;

                return properties;
            },
            matchesPropertiesFilter(propertyKey, propertyValue, nodeType, identifier) {
                if (!this.filters.search) {
                    this.showIdentifier(propertyKey + '-' + identifier)
                    return true;
                }
                if (!this.filterApply) {
                    return !this.hiddenIdentifiers.length || !this.hiddenIdentifiers.includes(propertyKey + '-' + identifier);
                }

                let propertyKeyLower = propertyKey.toLowerCase()
                if (typeof this.propertyLabels[nodeType] !== 'undefined' && typeof this.propertyLabels[nodeType][propertyKey] !== 'undefined') {
                    propertyKeyLower = this.propertyLabels[nodeType][propertyKey].toLowerCase()
                }

                let matchFound = true
                const searchLower = this.filters.search.toLowerCase()
                if (searchLower.includes('||')) {
                    const searchValues = searchLower.split('||');
                    matchFound = searchValues.some(value => propertyKeyLower.includes(value.toLowerCase()));
                } else if (searchLower.includes('&&')) {
                    const searchValues = searchLower.split('&&');
                    if (searchValues.some(value => !propertyKeyLower.includes(value.toLowerCase()))) {
                        matchFound = false
                    }
                } else {
                    if (!propertyKeyLower.includes(searchLower) && !this.matchesAnyFilter(this.filters, this.getSinglePropertyObject(propertyKey, propertyValue))) {
                        matchFound = false
                    }
                }

                if (matchFound) {
                    this.showIdentifier(propertyKey + '-' + identifier)
                } else {
                    this.hideIdentifier(propertyKey + '-' + identifier)
                }

                return matchFound
            },
            showIdentifier(identifier) {
                this.$emit('show-identifier', identifier)
            },
            hideIdentifier(identifier) {
                this.$emit('hide-identifier', identifier)
            },
            extractValues(obj) {
                let result = [];
                if (typeof obj === 'object' && obj !== null) {
                    Object.values(obj).forEach(value => {
                        if (typeof value === 'string') {
                            result.push(value);
                        } else if (Array.isArray(value)) {
                            result = result.concat(value);
                        } else if (typeof value === 'object') {
                            result = result.concat(this.extractValues(value));
                        }
                    });
                }
                return result;
            },
            matchesAnyFilter(filters, testProperties) {
                const formatDate = (dateStr) => {
                    const [day, month, year, time] = dateStr.split(/[. :]/);
                    return new Date(`${year}-${month}-${day}T${time || '00:00:00'}`);
                };

                // Extract all string values from testProperties, converting them to lowercase for case insensitive matching
                const testValues = this.extractValues(testProperties).reduce((acc, v) => {
                    if (typeof v === 'string') { // Ensure v is a string before calling toLowerCase
                        acc.push(v.toLowerCase());
                    }
                    return acc;
                }, []);

                // Search filter
                if (filters.search) {
                    const searchLower = filters.search.toLowerCase();
                    if (!testValues.some(value => value.includes(searchLower))) {
                        return false;
                    }
                }

                // State filter
                if (filters.state) {
                    const hasIndividual = typeof testProperties['isIndividual'] !== 'undefined';
                    const hasGlobal = typeof testProperties['isGlobal'] !== 'undefined';
                    switch (filters.state) {
                        case 'individualElement':
                            if (!hasIndividual || testProperties.isIndividual !== 'individualElement') {
                                return false;
                            }
                            break;
                        case 'globalElement':
                            if (!hasGlobal || testProperties.isGlobal !== 'globalElement') {
                                return false;
                            }
                            break;
                        case 'translated':
                            if ((hasGlobal && testProperties.isGlobal === 'globalElement') ||
                                (hasIndividual && testProperties.isIndividual === 'individualElement')) {
                                return false;
                            }
                            break;
                    }
                }

                // Date range filters for exportedFrom and exportedTill
                if (filters.exportedFrom || filters.exportedTill) {
                    if (typeof testProperties.exportDate === 'undefined' || !testProperties.exportDate || !testProperties.exportDate.value) {
                        return false;
                    }
                    const exportDate = formatDate(testProperties.exportDate.value);
                    let passFrom = true;
                    let passTill = true;
                    if (filters.exportedFrom && exportDate <= formatDate(filters.exportedFrom)) {
                        passFrom = false;
                    }
                    if (filters.exportedTill && exportDate >= formatDate(filters.exportedTill)) {
                        passTill = false;
                    }

                    return passFrom && passTill
                }

                // Date range filters for importedFrom and importedTill
                if (filters.importedFrom || filters.importedTill) {
                    if (typeof testProperties.importDate === 'undefined' || !testProperties.importDate || !testProperties.importDate.value) {
                        return false;
                    }
                    const importDate = formatDate(testProperties.importDate.value);
                    let passFrom = true;
                    let passTill = true;
                    if (filters.importedFrom && importDate < formatDate(filters.importedFrom)) {
                        passFrom = false;
                    }
                    if (filters.importedTill && importDate > formatDate(filters.importedTill)) {
                        passTill = false;
                    }

                    return passFrom && passTill
                }

                return true; // All non-empty filters matched
            },
            getPropertyLabel(key, nodetype) {
                if (typeof this.propertyLabels[nodetype] === 'undefined' || typeof this.propertyLabels[nodetype][key] === 'undefined') {
                    return key
                }

                return this.propertyLabels[nodetype][key]
            },
        },
    }
</script>
