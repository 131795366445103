<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            sort: {
                type: String,
                default: '',
            },
            visible: {
                type: Boolean,
                default: true,
            },
        },
    }
</script>

<template>
    <bg-column-wrapper :label="label" :sort="sort" :visible="visible">
        <slot>—</slot>
    </bg-column-wrapper>
</template>
