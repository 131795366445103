<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            sort: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                renderLabelValue: this.label,
                renderVisibleValue: true,
                pushed: false,
                settings: null,
                table: null,
                visibleData: [],
            }
        },

        watch: {
            // eslint-disable-next-line
            label: function(newValue, oldValue) {
                this.renderLabelValue = newValue
            },
        },

        computed: {
            newColumns: {
                set: function (value) {
                    this.$parent.newColumns = value
                },

                get: function () {

                    /**
                     * Faked parent for the BTableColumn
                     * 
                     * Provided methods:
                     * - some
                     * - map
                     * - splice
                     * - push
                     * 
                     * You can see the useage of these methods in the BTableColumn component:
                     * https://github.com/buefy/buefy/blob/dev/src/components/table/TableColumn.vue
                     */
                    return {

                        /**
                         * Array.prototype.some() pass through for BTableColumn.methods.addRefToTable()
                         * 
                         * @param  {function}  callback
                         */
                        some: (callback) => {
                            return this.$root.getParentByName(this, 'BTable').newColumns.forEach(item => { callback(item) })
                        },

                        /**
                         * Array.prototype.map() pass through for BTableColumn.beforeDestroy()
                         * 
                         * @param  {function}  callback
                         * @return {array}
                         */
                        map: (callback) => {
                            return this.$root.getParentByName(this, 'BTable').newColumns.map(callback)
                        },

                        /**
                         * Array.prototype.splice() pass through for BTableColumn.beforeDestroy()
                         * 
                         * @param  {number}  start
                         * @param  {number}  deleteCount
                         */
                        splice: (start, deleteCount) => {
                            this.$root.getParentByName(this, 'BTable').newColumns.splice(start, deleteCount)
                        },

                        /**
                         * Array.prototype.push() pass through for BTableColumn.methods.addRefToTable()
                         * 
                         * @param  {*}  element
                         */
                        push: (element) => {
                            let $column = this
                            let foundColumn = false
                            let $parent = this.$root.getParentByName(this, 'BTable')
                            let columns = []

                            $parent.newColumns.forEach(element => {
                                if (element.label == $column.renderLabel) {
                                    foundColumn = true
                                }
                                columns.push(element.label)
                            })

                            if (!foundColumn && $column.renderLabel !== '' && !$column.pushed) {
                                $column.pushed = true
                                $parent.newColumns.push(element)
                            }
                        },
                    }
                }
            },

            renderLabel: {
                get: function () {
                    return this.renderLabelValue != 'auto' ? this.renderLabelValue : ' '
                }
            },

            renderVisible: {
                set: function (value) {
                    console.log(value)
                },
                get: function () {
                    return this.renderVisibleValue
                }
            },
        },

        methods: {

            // eslint-disable-next-line
            display(settingsKey, newState) {
                if (this.settings == null) {
                    let settings = {}
                    let stack = this.$root.parentsStack(this, 'BTable')

                    stack.shift()
                    stack.pop()

                    stack.forEach(wrapper => {
                        if (typeof wrapper.display !== 'undefined') {
                            Object.keys(wrapper.display).forEach(key => {
                                settings[key] = wrapper.display[key]
                            })
                        }
                    })

                    this.settings = settings
                }

                if (typeof settingsKey === 'undefined') {
                    return this.settings
                }

                if (settingsKey in this.settings) {
                    if (typeof newState !== 'undefined') {
                        this.settings[settingsKey].state = newState
                    } else {
                        return this.settings[settingsKey].state
                    }
                }
            },
        },

        created() {
            this.table = this.$root.findParent(this, 'BgTable')
            if (!this.table) {
                this.table = this.$root.findParent(this, 'BgTableCollection')
            }
            this.table.columnWrapper[this._uid] = this
            this.$data._isTable = true
        },
    }
</script>

<template>
    <b-table-column :label="renderLabel" :field="sort" :sortable="sort.length > 2" :visible="renderVisibleValue">
        <slot>🚫 {{ $t('Content missing') }} 🚫</slot>
    </b-table-column>
</template>
