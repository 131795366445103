<script>
    export default {
        props: {
            collection: {
                type: Object,
                required: true,
            },
            label: {
                type: String,
                default: 'auto',
            },
            name: {
                type: String,
                required: true,
            },
            max: {
                type: Number,
                default: 4,
            },
            sort: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                renderListValue: [],
                display: {
                    relationUnlimited: {
                        state: false,
                        label: 'Alle Einträge anzeigen (bgrz. ' + this.max + ')',
                    }
                },
            }
        },

        computed: {
            renderList: {
                set: function (value) {
                    this.renderListValue = value
                },

                get: function () {
                    let resultSet = []

                    this.renderListValue.forEach(value => {
                        if (resultSet.length < this.max || this.display.relationUnlimited.state) {
                            resultSet.push(this.$root.translation(value[this.name][this.name + 'I18ns']).Name)
                        }
                    })

                    return resultSet
                }
            },
        },

        mounted() {
            let collectionKey = Object.keys(this.collection).find(key => {
                return key.indexOf(this.name) > -1
            })

            this.renderList = this.collection[collectionKey]
        },
    }
</script>

<template>
    <bg-column-list :label="label" :sort="sort" :list="renderList"/>
</template>
