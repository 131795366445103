<script>
    import TabDetailUser from '@/views/Details/User'
    import TabDetailGroup from '@/views/Details/Group'
    import TabDetailProduct from '@/views/Details/Product'
    import TabDetailSystem from '@/views/Details/System'
    import TabDetailComponent from '@/views/Details/Component'
    import TabDetailMedia from '@/views/Details/Media'
    import TabDetailFolder from '@/views/Details/Folder'
    import TabDetailProfile from '@/views/Details/Profile'

    export default {
        components: {
            TabDetailUser,
            TabDetailGroup,
            TabDetailProduct,
            TabDetailSystem,
            TabDetailComponent,
            TabDetailMedia,
            TabDetailFolder,
            TabDetailProfile,
        },

        data() {
            return {
                GET: {
                    for: null,
                    id: null,
                    tab: null,
                },
                activeTab: 0,
                fetched: {},
                focus: {},
                currentTab: 'details',
                isFetched: false,
                routeTable: {
                    User: {
                        collectionTypeId: 0,
                        route: 'users',
                        label: this.$t('Users'),
                        fetch: 'User',
                        tabs: ['details'],
                    },
                    Profile: {
                        collectionTypeId: 0,
                        route: 'sub-brands',
                        label: this.$t('Profile'),
                        fetch: 'User',
                        tabs: ['details'],
                    },
                    Group: {
                        collectionTypeId: 1,
                        route: 'sub-brands',
                        label: this.$t('Sub-brands'),
                        fetch: 'Group',
                        tabs: ['media', 'details', 'product-family'],
                    },
                    Product: {
                        collectionTypeId: 2,
                        route: 'product-families',
                        label: this.$t('Product families'),
                        fetch: 'Product',
                        tabs: ['media', 'details', 'systems'],
                    },
                    Component: {
                        collectionTypeId: 4,
                        route: 'components',
                        label: this.$t('Components'),
                        fetch: 'Component',
                        tabs: ['media', 'details'],
                    },
                    System: {
                        collectionTypeId: 3,
                        route: 'systems',
                        label: this.$t('Systems'),
                        fetch: 'System',
                        tabs: ['media', 'details', 'components'],
                    },
                    Folder: {
                        collectionTypeId: -1,
                        route: 'folders',
                        label: this.$t('Folders'),
                        fetch: 'Folder',
                        tabs: ['details'],
                    },
                },
            }
        },

        beforeCreate() {
            // redirect if reload
            if (!('for' in this.$route.params) || !('id' in this.$route.params)) {
                this.$router.push({name: 'sub-brands'})
            }
        },

        async created() {
            this.GET.id = this.param('id') != null ? this.param('id').toString() : null
            this.GET.for = this.param('for')

            // redirect if reload
            if (!('for' in this.$route.params) || !('id' in this.$route.params)) {
                this.$router.push({name: 'sub-brands'})
                return
            }

            this.GET.tab = this.param('tab') || this.routeTable[this.param('for')].tabs[0]

            let localeParam = ('locale' in this.$route.params) ? '?locale=' + this.$route.params.locale : ''
            await this.$root.fetch(this.routeTable[this.param('for')].fetch.toLowerCase() + '/' + this.$route.params.id + localeParam).then(response => {
                if (typeof this.routeTable[this.param('for')] !== 'undefined') {
                    this.fetched = response[this.routeTable[this.param('for')].fetch]
                    this.focus[this.GET.for.toLowerCase()] = this.GET.id

                    this.$route.params.source.push({
                        name: 're-details',
                        title: this.title(),
                        params: {
                            for: this.$route.params.for,
                            id: this.$route.params.id,
                            source: JSON.parse(JSON.stringify(this.$route.params.source)),
                        }
                    })

                    this.pushToRouter()
                }
            })
        },

        methods: {
            /**
             * Returns the GET param
             */
            param(key) {
                return (key in this.$route.params)
                    ? this.$route.params[key]
                    : null
            },

            /**
             * RenderView
             */
            view(tab, name) {
                return this.isFetched && this.GET.tab == tab && (typeof name === 'undefined' || this.GET.for == name)
            },

            title() {
                return this.routeTable[this.param('for')].fetch === 'User' ? this.fetched.FirstName + ' ' + this.fetched.LastName : this.$root.translation(this.fetched[this.routeTable[this.param('for')].fetch + 'I18ns']).Name
            },

            /**
             * Returns the GET param
             */
            pushToRouter() {
                this.$store.commit('mainPage', {
                    current: this.title(),
                    stack: this.$route.params.source
                })

                this.isFetched = true
            },

            /**
             * Changes the selected tab
             *
             * @param {string}
             */
            changeTab(tabName) {
                // this.$router.push('/details?for=' + this.param('for') + '&id=' + this.param('id') + '&tab=' + tabName)
                this.GET.tab = tabName
            }
        },
    }
</script>

<template>
    <section class="section" v-if="('for' in $route.params && 'id' in $route.params)">
        <div class="container-fluid">

            <div class="b-tabs">
                <nav class="tabs is-boxed">
                    <ul>
                        <li v-if="this.routeTable[this.param('for')].tabs.includes('media') && $root.isReadAllowed('File')"
                            :class="{ 'is-active': GET.tab === 'media' }">
                            <a @click="changeTab('media')"><span>{{ $t('Media') }}</span></a>
                        </li>
                        <li v-if="this.routeTable[this.param('for')].tabs.includes('details')"
                            :class="{ 'is-active': GET.tab === 'details' }">
                            <a @click="changeTab('details')"><span>{{ $t('Details') }}</span></a>
                        </li>
                        <li v-if="this.routeTable[this.param('for')].tabs.includes('product-family') && $root.isReadAllowed('Product')"
                            :class="{ 'is-active': GET.tab === 'product-family' }">
                            <a @click="changeTab('product-family')"><span>{{ $t('Product families') }}</span></a>
                        </li>
                        <li v-if="this.routeTable[this.param('for')].tabs.includes('systems') && $root.isReadAllowed('System')"
                            :class="{ 'is-active': GET.tab === 'systems' }">
                            <a @click="changeTab('systems')"><span>{{ $t('Systems') }}</span></a>
                        </li>
                        <li v-if="this.routeTable[this.param('for')].tabs.includes('components') && $root.isReadAllowed('Component')"
                            :class="{ 'is-active': GET.tab === 'components' }">
                            <a @click="changeTab('components')"><span>{{ $t('Components') }}</span></a>
                        </li>
                    </ul>
                </nav>

                <section class="tab-content card is-card-table is-card-detail">
                    <div class="tab-item">
                        <!-- Detail Views -->
                        <tab-detail-folder v-if="view('details', 'Folder')" :fetched="fetched"/>
                        <tab-detail-group v-if="view('details', 'Group')" :fetched="fetched"/>
                        <tab-detail-product v-if="view('details', 'Product')" :fetched="fetched"/>
                        <tab-detail-system v-if="view('details', 'System')" :fetched="fetched"/>
                        <tab-detail-component v-if="view('details', 'Component')" :fetched="fetched"/>
                        <tab-detail-user v-if="view('details', 'User')" :fetched="fetched"/>
                        <tab-detail-profile v-if="view('details', 'Profile')" :fetched="fetched"/>

                        <!-- Tables -->
                        <bg-table-products v-if="view('product-family') && $root.isReadAllowed('Product')"
                                           :filter="{ GroupId:   GET.id }"/>
                        <bg-table-systems v-if="view('systems') && $root.isReadAllowed('System')"
                                          :filter="{ ProductId: GET.id }"/>
                        <bg-table-components v-if="view('components') && $root.isReadAllowed('Component')"
                                             :filter="{ SystemId:  GET.id }"/>

                        <!-- Media -->
                        <tab-detail-media :upload="true" v-if="view('media') && $root.isReadAllowed('File')"
                                          :collection="GET.for" :collection-id="GET.id"
                                          :collection-type="routeTable[GET.for].collectionTypeId"
                                          :redirect="'/re-details?for=' + GET.for + '&id=' + GET.id + '&tab=media'"/>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>
