<script>
    export default {
        props: {
            fetched: {},
            create: {
                type: Boolean,
                default: false
            },
        },

        methods: {
            beforeSend(content, call) {
                content['RequiredCount'] = parseInt(content['RequiredCount'])
                return [content, this.$root.stringPlaceholders(call, content)]
            }
        },
    }
</script>

<template>
    <div>
        <div v-if="create"><p>🦉 {{ $t('This can not be created.') }}</p></div>
        <bg-form v-if="!create" :beforeSend="'beforeSend'" call="folder/${__ID}">

            <bg-form-input :label="$t('Item number')"    :prefill="fetched.SapId" :size="12" :displayAsTable="true" />
            <bg-form-input :label="$t('Name')"             :prefill="$root.translation(fetched.GroupI18ns).Name" :size="12" :displayAsTable="true" />
            <bg-form-input :label="$t('Name for web')"    :prefill="$root.translation(fetched.GroupI18ns).WebName || $root.translation(fetched.GroupI18ns).Name" :size="12" :displayAsTable="true" />
            <bg-form-input :label="$t('Description (short)')" :prefill="$root.translation(fetched.GroupI18ns).DescriptionShort" :size="12" :displayAsTable="true" />
            <bg-form-input :label="$t('Description')"      :prefill="$root.translation(fetched.GroupI18ns).Description" :size="12" :textarea="true" :displayAsTable="true" />

        </bg-form>
    </div>
</template>