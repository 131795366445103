<script>
    export default {
        props: {
            create: {
                type: String,
                default: '',
            },
            searchValue: {
                type: String,
                default: '',
            },
            siteCount: {
                type: Boolean,
                default: true,
            },
            siteColumns: {
                type: Boolean,
                default: true,
            },
            object: {
                type: String,
                default: '',
            }
        },

        data() {
            return {
                search: '',
                dataSize: '10',
                hrRendered: false,
                someTrue: true,
            }
        },

        watch: {
            search: function(newValue) {
                this.$root.Storage.set(this.object + '-search', newValue)
                this.$emit('search', newValue);
            },

            dataSize: function(newValue) {
                this.$emit('count', newValue);
            },

            searchValue: function(newValue) {
                this.search = newValue
            },
        },

        updated() {
            this.$parent.$forceUpdate()
        },

        methods: {
            getParentSettings () {
                let renderSettings = {}

                for (let key in this.$parent.settings.functions) {
                    renderSettings[key] = this.$parent.settings.functions[key]
                }

                return renderSettings
            },

            clickRowView() {
                this.$router.push(this.create)
            },
        },
    }
</script>

<template>
    <div class="card-toolbar">
        <div class="level">
            <div class="level-left">
                <div class="level-item" v-if="siteCount">
                    <div class="select">
                        <select v-model="dataSize">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                    <label class="count-label">{{ $t('Show items') }}</label>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <b-field>
                        <b-input type="search" icon="magnify" :placeholder="$t('Search everywhere...')" v-model="search" />
                    </b-field>
                </div>
                <div class="level-item" v-if="create.length > 2">
                    <button @click="clickRowView" class="button is-success" style="border-radius: 4px"><span class="icon is-small"><i class="mdi mdi-plus"></i></span>&nbsp; {{ $t('Create') }}</button>
                </div>
                <div class="level-item" v-if="siteColumns">
                    <b-dropdown position="is-bottom-left" aria-role="menu">
                        <button class="button is-success" slot="trigger" style="border-radius: 4px"><span class="icon is-small"><i class="mdi mdi-settings"></i></span>&nbsp; {{ $t('Columns') }} &nbsp;<span class="icon is-small"><i class="mdi mdi-chevron-down"></i></span></button>

                        <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>

                            <div class="modal-card modal-card--for-column-settings" style="width:300px;" >
                                <section class="modal-card-body" style="padding: .5rem 1.5rem">
                                    <div v-for="(column, name) in $parent.settings.functions" :key="name">
                                        <div>
                                            <b-checkbox v-model="$parent.settings.visibilities[name]" @input="$parent.updateSettings(true)"><strong style="font-size: 1.15rem">{{ name }}</strong></b-checkbox>
                                            <div v-for="(config, key) in column" :key="key" style="padding-left:1rem">
                                                <b-checkbox @input="$parent.updateSettings(true)" :disabled="!someTrue" v-if="typeof config !== 'object' || !('permission' in config) || config.permission.includes($root.Storage.get('user').roleShortcut)" v-model="config.state"><span v-if="['filesDebug', 'editedDebug'].includes(key)">📌 </span>{{ config.label }}</b-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>

                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>
