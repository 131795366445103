<template>
    <footer v-show="isFooterBarVisible" class="footer">
        <div class="container-fluid">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        Copyright &copy; {{ year }}, {{ appName }}
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        BG-Graspointner Online Mapping Tool |<strong>&nbsp;{{ getPlatform() }}&nbsp;</strong>| Version {{ version }}
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import moment from 'moment'
    import {mapState} from 'vuex'

    export default {
        name: "FooterBar",
        props: {
            appName: {
                type: String,
                default: null,
            },
            version: {
                type: String,
                default: null,
            },
        },
        computed: {
            year() {
                return moment().year()
            },
            ...mapState([
                'isFooterBarVisible',
            ])
        },
        methods: {
            getPlatform() {
                let stage = process.env.NODE_ENV
                if (stage === 'production' && window.location.hostname !== 'data.bg-graspointner.com') {
                    stage = 'staging'
                }

                return stage;
            }
        },
    }
</script>
