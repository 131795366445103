<template>
    <div class="aside-tools">
        <div class="aside-logo" :class="{'is-wide':isAsideWideActive}">
            <img v-if="isAsideWideActive" src="/data-sources/logo/logo-red-green.png">
            <img v-if="!isAsideWideActive" src="/data-sources/logo/logo-red-green-small.png">
        </div>
        <a class="aside-close" :class="{'is-hidden-desktop':isMainMenu}" @click="close">
            <b-icon icon="close" custom-size="default"/>
        </a>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "AsideTools",
        components: {},
        props: {
            isMainMenu: {
                type: Boolean,
                default: false,
            },
            hasCloseEvent: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapState([
                'isAsideWideActive',
            ])
        },
        methods: {
            close() {
                if (this.hasCloseEvent) {
                    this.$emit('close-click')
                } else {
                    this.$jbUtils.overlayHide()
                }
            }
        }
    }
</script>
