<script>
    export default {
        props: {
            value: {
                required: true,
            },
            required: {
                required: true,
            },
            label: {
                type: String,
                default: 'auto',
            },
            sort: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                display: {
                    progressFiles: {
                        state: false,
                        label: this.$t('Hide values'),
                    }
                },
            }
        },
    }
</script>

<template>
    <bg-column-wrapper :label="label" :sort="sort">
        <b-progress :value="(((value >= required) ? 1 : (value / required)) * 100)" size="is-large"
                    :type="value < required ? 'is-danger' : 'is-success'" :show-value="!display.progressFiles.state">
            <p class="has-text-centered">{{ value }} / {{ required }}</p>
        </b-progress>
        <slot></slot>
    </bg-column-wrapper>
</template>
