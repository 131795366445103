<script>
    import Card from '@/components/Card'

    export default {
        components: {
            Card,
        },

        data() {
            return {
                currentFolder: '',
            }
        },

        mounted() {
            this.$root.restrictCountries('*')
            this.$store.commit('mainPage', {
                current: this.$t('Media'),
                stack: [{
                    'path': '/media',
                    'title': this.$t('Media'),
                }]
            })

            if (typeof this.$route.query['f'] !== 'undefined') {
                this.currentFolder = this.$route.query['f']
            }
        },
    }
</script>

<template>
    <section class="section">
        <div class="container-fluid">
            <card class="is-card-table has-pagination has-bottom-left-hidden">
                <bg-table-media update="/re-media" :setCurrentFolder="currentFolder"/>
            </card>
        </div>
    </section>
</template>
