<template>
    <aside v-show="isAsideVisible" class="is-right aside-menu slideInRight animated faster">
        <div class="aside-wrapper">
            <div class="menu is-menu-main">
                <aside-tools :isMainMenu="true"/>
                <aside-menu-container :menu-list='menu' :is-update-by-global-active-state="true"
                                      container-index-prefix="main" :hasTooltips="true" @menu-click="menuClick"/>
                <div class="menu-admin-actions" v-if="isAdmin()">
                    <ul class="menu-list">
                        <li>
                            <a @click="goToAdmin">
                                <span class="icon">
                                    <i class="mdi mdi-cogs is-medium"></i>
                                </span>
                                <span class="menu-item-label">{{ $t('Admin') }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
import AsideTools from '@/components/AsideTools'
import AsideMenuContainer from '@/components/AsideMenuContainer'
import {mapState} from 'vuex'

export default {
    name: "AsideMenu",
    components: {
        AsideTools,
        AsideMenuContainer
    },
    props: {
        menu: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        ...mapState([
            'isAsideVisible',
        ])
    },
    methods: {
        // eslint-disable-next-line
        menuClick(item) {
            // Note the comment above the function
            // It asks eslint to ignore declared, but unused variable (not good for production)
            // So, if you're not going to have some logic in here,
            // you may then safely replace menuClick(item) with menuClick() and leave the method empty
        },
        htmlClass(val) {
            let c = 'is-one-hero-layout'

            if (!val)
                this.$jbUtils.htmlAddClass(c)
            else
                this.$jbUtils.htmlRemoveClass(c)
        },
        isAdmin() {
            let user = this.$root.Storage.get('user')

            return user && user.roleShortcut === 'admin'
        },
        goToAdmin() {
            this.$router.push({name:'admin'})
        },
    },
    watch: {
        isAsideVisible(newVal) {
            this.htmlClass(newVal)
        }
    }
}
</script>
