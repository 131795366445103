<script>
    export default {
        props: {
            fetched: {},
            create: {
                type: Boolean,
                default: false
            },
        },

        methods: {
            beforeSend(content, call) {
                content['RequiredCount'] = parseInt(content['RequiredCount'])
                return [content, this.$root.stringPlaceholders(call, content)]
            },

            getI18nNames($n2nName, $objectName) {
                let data = '';
                this.fetched[$n2nName].forEach(row => {
                    data += this.$root.translation(row[$objectName][$objectName + 'I18ns']).Name + ', '
                })

                return data.slice(0, -2);
            },
        },
    }
</script>

<template>
    <div>
        <div v-if="create"><p>🦉 {{ $t('This can not be created.') }}</p></div>
        <bg-form v-if="!create" :beforeSend="'beforeSend'" call="folder/${__ID}">

            <bg-form-input :label="$t('Item number')"    :prefill="fetched.SapId" :size="12" :displayAsTable="true" />
            <bg-form-input :label="$t('Name')"             :prefill="$root.translation(fetched.ProductI18ns).Name" :size="12" :displayAsTable="true" />
            <bg-form-input :label="$t('Name for web')"    :prefill="$root.translation(fetched.ProductI18ns).WebName || $root.translation(fetched.ProductI18ns).Name" :size="12" :displayAsTable="true" />
            <bg-form-input :label="$t('Description (short)')" :prefill="$root.translation(fetched.ProductI18ns).DescriptionShort" :size="12" :displayAsTable="true"/>
            <bg-form-input :label="$t('Description')"      :prefill="$root.translation(fetched.ProductI18ns).Description" :size="12" :textarea="true" :displayAsTable="true" />
            <div class="is-12 column">
                <hr>
                <h2 class="title" style="font-size: 1.25rem;">{{ $t('Properties') }}</h2>
            </div>
            <bg-form-input :label="$t('Application areas')"      :prefill="getI18nNames('Product2ApplicationAreas', 'ApplicationArea')" :size="12" :textarea="true" :displayAsTable="true" v-if="fetched.Product2ApplicationAreas && fetched.Product2ApplicationAreas.length" />

        </bg-form>
    </div>
</template>