<template>
    <div id="app" v-if="!doRefresh">
        <notification v-if="notification.display" :class="'is-' + notification.type">
            <div>
                <b-icon v-if="notification.icon.length > 1" :icon="notification.icon" custom-size="default"></b-icon>
                {{ notification.message }}
            </div>
        </notification>
        <nav-bar/>
        <aside-menu :menu='menu'/>
        <title-bar/>
        <router-view/>
        <footer-bar app-name="Online Mapping Tool" :version="version"/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Notification from '@/components/Notification'
    import NavBar from '@/components/NavBar.vue'
    import AsideMenu from '@/components/AsideMenu'
    import TitleBar from '@/components/TitleBar'
    import FooterBar from '@/components/FooterBar'

    export default {

        name: 'home',

        components: {
            Notification,
            NavBar,
            AsideMenu,
            TitleBar,
            FooterBar
        },

        data() {
            return {
                notification: {
                    icon: '',
                    type: '',
                    message: '',
                    display: false
                },
                menu: [],
                version: '',
                doRefresh: false,
            }
        },

        methods: {
            /**
             * Displays a notification on top of the site.
             *
             * @param {string}           message
             * @param {string|undefined} type
             * @param {string|undefined} icon
             */
            notify: function (message, type, icon) {
                this.notification.type = type || 'danger'
                this.notification.icon = icon || ''
                this.notification.message = message
                this.notification.display = true

                setTimeout(() => {
                    this.notification.icon = ''
                    this.notification.type = ''
                    this.notification.message = ''
                    this.notification.display = false
                }, 4000)
            },

            refresh () {
                this.doRefresh = true

                // setTimeout has two main benefits:
                // - push the value change back in the event queue / cpu scheduler
                // - don't get optimized away by javascript engines
                setTimeout(() => {
                    this.doRefresh = false
                }, 0);
            },

            buildMenu: function () {
                // Set menu for authorized user
                // You're free to use computed properties for that, if you wish to
                let possibleMenu = [
                    {
                        routeName: 'sub-brands',
                        icon: 'layers',
                        label: this.$t('Sub-brands'),
                        permission: 'Group'
                    },
                    {
                        routeName: 'product-families',
                        icon: 'tag',
                        label: this.$t('Product families'),
                        permission: 'Product'
                    },
                    {
                        routeName: 'systems',
                        icon: 'rhombus-split',
                        label: this.$t('Systems'),
                        permission: 'System'
                    },
                    {
                        routeName: 'components',
                        icon: 'chip',
                        label: this.$t('Components'),
                        permission: 'Component'
                    },
                    {
                        routeName: 'collection',
                        icon: 'view-list',
                        label: this.$t('Collection'),
                        permission: 'Group'
                    },
                    {
                        routeName: 'media',
                        icon: 'camera',
                        label: this.$t('Media'),
                        permission: 'File'
                    },
                    {
                        routeName: 'folders',
                        icon: 'folder',
                        label: this.$t('Folders'),
                        permission: 'Folder'
                    },
                    {
                        routeName: 'users',
                        icon: 'account',
                        label: this.$t('Users'),
                        permission: 'User'
                    },
                    // {
                    //     routeName: 'tags',
                    //     icon: 'tag',
                    //     label: this.$t('Tags'),
                    //     permission: 'Tag'
                    // }
                ];

                let menu = []
                let user = this.$root.Storage.get('user')
                if (user !== null && user.permission !== undefined) {
                    for (let i = 0; i < possibleMenu.length; i++) {
                        let item = possibleMenu[i]
                        if (user.permission[item['permission']] < 100) continue

                        let menuItem = {
                            routeName: item['routeName'],
                            icon: item['icon'],
                            label: item['label'],
                        }
                        menu.push(menuItem)
                    }
                }

                this.menu = [menu]
            }
        },
        mounted() {
            this.buildMenu()
            const timestamp = Date.now()
            this.$root.axios().get('../version.json?v=' + timestamp)
                .then((response) => {
                    this.version = response.data.version
                })
        }
    }
</script>
