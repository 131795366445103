<template>
    <b-icon v-if="icon" custom-size="is-medium" :class="wrapperClass" :icon="icon"/>
</template>

<script>
    export default {
        name: "AsideItemIcon",
        props: {
            icon: {
                type: String,
                default: null,
            },
            updateMark: {
                type: String,
                default: null,
            }
        },
        computed: {
            wrapperClass() {
                let classBase = {};

                if (this.updateMark) {
                    classBase['has-update-mark'] = true;
                    classBase['is-update-state-' + this.updateMark] = true;
                }

                return classBase;
            },
        }
    }
</script>
