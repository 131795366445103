<template>
    <li :class="{'is-active':isDropdownActive}" v-tooltip.right="tooltip">
        <a :href="itemHref" :class="aClass" @click="menuClick" v-touch="touchTap">
            <aside-item-icon :icon="item.icon" :updateMark="item.updateMark"/>
            <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
            <div v-if="item.updateLabel" class="update-label">
        <span :class="updateLabelSpanClass">
          <b-icon v-if="item.updateLabel.icon" :icon="item.updateLabel.icon" custom-size="default"/>
          <span v-if="item.updateLabel.label">{{ item.updateLabel.label }}</span>
        </span>
            </div>
            <div v-else-if="hasSubmenu" class="dropdown-label">
                <b-icon :icon="dropdownIcon" custom-size="default"/>
            </div>
        </a>
        <template v-if="hasDropdown">
            <aside-menu-list-plain v-for="(subItemIterated, index) in item.menu" :key="index"
                                   :menuList="subItemIterated" :isSubmenuList="true"/>
        </template>
    </li>
</template>

<script>
    import AsideItemIcon from '@/components/AsideItemIcon'
    import {mapState} from 'vuex'

    const find = require('lodash/find')

    export default {
        name: "AsideMenuItem",
        components: {
            AsideItemIcon,
        },
        data() {
            return {
                isActiveForcedState: null,
                isDropdownActive: false,
            }
        },
        props: {
            item: {
                type: Object,
                default: null,
            },
            updateLabelDefaultState: {
                type: String,
                default: 'warning',
            },
            hasDropdownEnabled: {
                type: Boolean,
                default: false,
            },
            isUpdateByGlobalActiveState: {
                type: Boolean,
                default: false,
            },
            indexKey: {
                type: String,
                default: null,
            },
            hasTooltip: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            menuClick() {
                this.$emit('menu-click', this.item)

                if (this.hasDropdown) {
                    this.isDropdownActive = (!this.isDropdownActive)
                    return false
                }

                if (this.item.menu) {
                    this.$store.commit('primaryMenuIsActiveForcedState', {
                        globalState: false,
                        thisState: true,
                        thisKey: this.indexKey
                    })
                    this.$jbUtils.overlayShow('is-aside-secondary-active')
                    this.$store.commit('secondaryMenu', this.item)
                } else if (this.item.routeName && this.$router) {
                    this.$router.push({name: this.item.routeName})
                    this.$jbUtils.overlayHide()
                    return false
                }
            },
            touchTap() {
                if (this.isSafari) {
                    this.menuClick()
                }
            },
            hasActive(menu) {
                let index = -1,
                    length = menu == null ? 0 : menu.length;

                while (++index < length) {
                    if (find(menu[index], 'active')) {
                        return true;
                    }

                    /*if (this.hasRouter && this.$route.name === this.item.routeName)
                        return true*/

                    let withSubSubMenu = find(menu[index], 'menu');

                    if (withSubSubMenu && this.hasActive(withSubSubMenu.menu)) {
                        return true;
                    }
                }

                return false;
            }
        },
        computed: {
            aClass() {
                let classBase = {
                    'is-active': this.isActive,
                    'has-update-label': (this.item.updateLabelIcon || this.item.updateLabel),
                    'has-dropdown-label': this.hasSubmenu
                };

                if (this.item.state) {
                    classBase['is-state-' + this.item.state] = true;
                    classBase['is-hoverable'] = true;
                }

                return classBase;
            },
            updateLabelSpanClass() {
                let classBase = 'tag is-';

                if (this.item.updateLabel && this.item.updateLabel.state) {
                    classBase += this.item.updateLabel.state;
                } else {
                    classBase += this.updateLabelDefaultState;
                }

                return classBase;
            },
            dropdownIcon() {
                if (this.hasDropdown) {
                    return (this.isDropdownActive) ? 'minus' : 'plus';
                } else {
                    return 'chevron-right'
                }
            },
            isActive() {
                if (this.isActiveForcedState !== null)
                    return this.isActiveForcedState

                if (this.item.active)
                    return true

                if (this.hasRouter && this.$route.name === this.item.routeName)
                    return true

                return (this.item.menu && this.hasActive(this.item.menu))
            },
            hasSubmenu() {
                return !!this.item.menu
            },
            hasDropdown() {
                return (this.hasDropdownEnabled && this.item.menu)
            },
            hasRouter() {
                return !!this.$router
            },
            itemHref() {
                if (this.item && this.item.href)
                    return this.item.href

                return null
            },
            tooltip() {
                if (this.hasTooltip && !this.isAsideWideActive && this.item.label) {
                    return this.item.label
                }

                return null
            },
            isSafari() {
                return (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                    navigator.userAgent &&
                    navigator.userAgent.indexOf('CriOS') === -1 &&
                    navigator.userAgent.indexOf('FxiOS') === -1)
            },
            ...mapState([
                'isAsideWideActive',
                'primaryMenuIsActiveForcedState'
            ])
        },
        watch: {
            primaryMenuIsActiveForcedState(newVal) {
                if (this.isUpdateByGlobalActiveState) {
                    if (!newVal) {
                        this.isActiveForcedState = null;
                    } else {
                        if (newVal.thisKey && newVal.thisKey === this.indexKey)
                            this.isActiveForcedState = newVal.thisState;
                        else
                            this.isActiveForcedState = newVal.globalState;
                    }
                }
            }
        }
    }
</script>
