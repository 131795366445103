<template>
    <b-modal @close="close" :active.sync="visible" has-modal-card custom-class="bg-upload__modal">
        <div class="card">
            <div class="card-content">
                <div class="columns">
                    <div class="column">
                        <div style="position: relative">

                            <div class="level">
                                <div class="level-left">
                                    <p class="is-size-5">
                                        <strong>{{ $t('State Overview') }}</strong>
                                    </p>
                                </div>
                                <div class="level-right"></div>
                            </div>

                            <div style="display:grid;grid-template-columns: repeat(auto-fill, 219px);gap:14px;padding:.75rem 0;margin: -0.75rem 0 0.8rem;border-top:1px solid #dbdbdb;border-bottom:1px solid #dbdbdb;">
                                <template v-for="(country, cKey) in getCountries(show)">
                                    <div :key="cKey">
                                        <p style="margin-bottom:6px"><b>{{ $root.translation(country.CountryI18ns, {}, 'de_AT').Name }}</b></p>

                                        <div v-if="loaded">
                                            <div v-for="(language, lKey) in country.Languages" :key="lKey" style="margin:2px 0">
                                                <template v-if="language.LocaleCode in computed.states && computed.states[language.LocaleCode] != null">
                                                    <b-checkbox :disabled="true" :value="computed.states[language.LocaleCode]" :indeterminate="computed.states[language.LocaleCode] === -1" :type="computed.states[language.LocaleCode] === -1 ? 'is-light' : 'is-success'" style="opacity: 1">
                                                        <span style="color:black">{{ $root.translation(language.LanguageI18ns, {}, 'de_AT').Name }}</span>
                                                    </b-checkbox>
                                                </template>
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{ $t('Entries are loading...') }}
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="level" style="align-items:center">
                                <div class="level-left" style="display:flex;gap:.5rem">
                                    <b-checkbox :disabled="true" :value="false" type="is-success" style="opacity: 1">
                                        <span style="color:black">{{ $t('state:MissingOrDeleted') }}</span>
                                    </b-checkbox>
                                    <b-checkbox :disabled="true" :value="true" type="is-success" style="opacity: 1">
                                        <span style="color:black">{{ $t('state:active') }}</span>
                                    </b-checkbox>
                                    <b-checkbox :disabled="true" :indeterminate="true" type="is-light" style="opacity: 1">
                                        <span style="color:black">{{ $t('state:InactiveOrNotAvailable') }}</span>
                                    </b-checkbox>
                                </div>
                                <div class="level-right">
                                    <button @click="close" class="button is-primary">{{ $t('Close') }}</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        row: {
            default: null,
        },
        object: {
            default: null,
        },
    },

    data() {
        return {
            visible: false,
            loaded: false,
            computed: {
                states: {},
            },
        }
    },

    watch: {
        show: function (newValue) {
            if (newValue == false) {
                this.states = {}
                this.loaded = false
            }

            this.visible = newValue
        },
    },

    methods: {
        /**
         * Returns all countries with their respective languages avaiable.
         *
         * > Returns null if the languages are not loaded yet.
         *
         * @return {Array}
         */
        getCountries(show) {
            if (show) {
                if (Object.keys(this.computed.states).length == 0) {
                    this.$root.countries.forEach(country => {
                        country.Languages.forEach(language => {
                            this.computed.states[language.LocaleCode] = null

                            this.$root.fetch(this.row.CollectionType.PhpObject, {
                                locale: language.LocaleCode,
                                'filter[Id]': this.row.Id,
                            }).then(response => {
                                let result = (this.object in response
                                    && response[this.object].length > 0
                                    && (this.object + 'I18ns') in response[this.object][0])
                                    ? response[this.object][0][this.object + 'I18ns'][0].StateIdI18n
                                    : null

                                this.computed.states[language.LocaleCode] = result === 1 ? true : (result !== null && result < 10 ? -1 : false)

                                this.checkLoaded()
                            })
                        })
                    })
                }

                return this.$root.countries
            }

            return null
        },

        checkLoaded() {
            let found = false

            Object.values(this.computed.states).forEach(x => {
                if (x === null) {
                    found = true
                    return false
                }
            })

            this.loaded = !found
        },

        close() {
            this.computed.states = {}
            this.loaded = false

            this.$emit('close')
        },
    },
}
</script>
