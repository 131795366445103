<script>
export default {
    props: {
        upload: {
            type: Boolean,
            default: false,
        },
        folderId: {
            type: [String, Number],
        },
        collection: {
            type: String,
        },
        collectionId: {
            type: String,
        },
        collectionType: {
            type: [String, Number],
        },
        redirect: {
            type: String,
        },
    },

    data () {
        return {
            updateRoute: this.redirect
        }
    },

    computed: {
        filter: {
            get () {
                let filterObject = {}

                if (this.collection.length > 0) {
                    filterObject[this.collection + 'Id'] = this.collectionId
                }

                return filterObject
            }
        },
    },
}
</script>

<template>
    <div>
        <bg-table-media :filter="filter" :collection-type="collectionType" :update="updateRoute" v-slot="data">

            <bg-upload v-if="upload" :folder-id="data.currentFolder" :collection="collection" :collection-id="collectionId" />
            <hr v-if="upload">
            <h2 v-if="!upload" class="title" style="font-size: 1.25rem">{{ $t('Files') }}</h2>
            <h2 v-if="upload" class="title" style="font-size: 1.25rem">{{ $t('Already uploaded files') }}</h2>

        </bg-table-media>
    </div>
</template>
