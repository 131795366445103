<script>
    export default {
        props: {
            fetched: {},
            create: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                UserRole: [],
            }
        },

        async created() {
            await this.$root.fetch('user-role').then(response => {
                this.UserRole = response.UserRole
            })
        },

        methods: {
            beforeSend(content, call) {
                return [content, this.$root.stringPlaceholders(call, content)]
            },
        },
    }
</script>

<template>
    <div>
        <bg-form v-if="create" :button="$t('Create user')" :beforeSend="'beforeSend'" call="user"
                 @afterSend="$emit('afterSend')">
            <bg-form-input :label="$t('First name')" name="FirstName" :size="3"/>
            <bg-form-input :label="$t('Last name')" name="LastName" :size="3"/>
            <bg-form-input :label="$t('Email')" name="Email" :size="3"/>
            <bg-form-input :label="$t('NEOS username')" name="NeosUsername" :size="3"/>
            <bg-form-input :label="$t('Username')" name="Username" :size="3"/>
            <bg-form-input :label="$t('Password (min. 5 characters)')" name="Password" type="password" :size="3"/>
            <bg-form-select
                    :size="3"
                    :label="$t('Role')"
                    name="UserRoleId"
                    :options="UserRole"
                    :option-id="option => option.Id"
                    :placeholder="$t('Please select...')"
                    :option-label="option => $root.translation(option.UserRoleI18ns, {}, 'de_AT').Name"/>
            <bg-form-input :label="$t('ID')" :size="3" :prefill="$t('Will be created automatically')"/>
        </bg-form>
        <bg-form v-else :button="$t('Save changes')" :beforeSend="'beforeSend'" call="user/${__ID}"
                 @afterSend="$emit('afterSend')">
            <bg-form-input :label="$t('First name')" name="FirstName" :size="3" :prefill="fetched.FirstName"/>
            <bg-form-input :label="$t('Last name')" name="LastName" :size="3" :prefill="fetched.LastName"/>
            <bg-form-input :label="$t('Email')" name="Email" :size="3" :prefill="fetched.Email"/>
            <bg-form-input :label="$t('NEOS username')" name="NeosUsername" :size="3" :prefill="fetched.NeosUsername"/>
            <bg-form-input :label="$t('Username')" name="Username" :size="3" :prefill="fetched.Username"/>
            <bg-form-input :label="$t('Password (min. 5 characters)')" name="Password" :password="true" :size="3"/>
            <bg-form-select
                    :size="3"
                    :label="$t('Role')"
                    name="UserRoleId"
                    :options="UserRole"
                    :prefill="fetched.UserRoleId"
                    :option-id="option => option.Id"
                    :placeholder="$t('Please select...')"
                    :option-label="option => $root.translation(option.UserRoleI18ns, {}, 'de_AT').Name"/>
            <bg-form-input :label="$t('ID')" :size="3" :prefill="fetched.Id"/>
        </bg-form>
    </div>
</template>
